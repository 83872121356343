import React from 'react';
import PropTypes from 'prop-types';
import { Cell, Content } from './styles';
import withWindowResize from '../../../../shared/hocs/withWindowResize';
import SpecialCell from '../../GridSpecialCell';
import { MEDIUM } from '../../../../shared/constants/width-constants';

const GridPlansCell = ({ children, gridColumn, cellPosition, alignItems, width, ...props }) => {
  const style = {};
  switch (cellPosition) {
    case 'leftTop':
      style.borderRadius = '1rem 0 0 0';
      style.borderBottom = 'none';
      style.borderRight = 'none';
      style.justifyContent = 'center';
      break;
    case 'middleTop':
      style.borderRadius = '0';
      style.borderBottom = 'none';
      style.borderRight = 'none';
      style.justifyContent = 'center';
      break;
    case 'rightTop':
      style.borderRadius = '0 1rem 0 0';
      style.borderBottom = 'none';
      style.justifyContent = 'center';
      break;
    case 'leftTopSecound':
      style.borderRadius = '1rem 0 0 0';
      break;
    case 'rightMiddle':
      style.borderRadius = '0';
      style.justifyContent = 'center';
      style.borderTop = 'none';
      break;
    case 'leftMiddle':
      style.borderRadius = '0';
      style.justifyContent = 'space-between';
      style.borderTop = 'none';
      style.borderRight = 'none';
      break;
    case 'middleMiddle':
      style.borderRadius = '0';
      style.justifyContent = 'center';
      style.borderTop = 'none';
      style.borderRight = 'none';
      break;
    case 'leftBottom':
      style.borderRadius = '0 0 0 1rem';
      style.borderTop = 'none';
      style.borderRight = 'none';
      style.justifyContent = 'space-between';
      break;
    case 'middleBottom':
      style.borderRadius = '0';
      style.justifyContent = 'center';
      style.borderTop = 'none';
      style.borderRight = 'none';
      break;
    case 'rightBottom':
      style.borderRadius = '0 0 1rem 0';
      style.justifyContent = 'center';
      style.borderTop = 'none';
      break;
    default:
      break;
  }

  if (alignItems) {
    style.alignItems = 'center';
  }

  if (width.lessThan(MEDIUM)) {
    style.borderRadius = '0';
    style.padding = '1.4rem 1.7rem';
  }

  const Component = typeof children !== 'string' && SpecialCell[children.component];

  return (
    <Cell gridColumn={gridColumn}>
      <Content {...{ ...style, ...props }}>{Component ? <Component /> : children}</Content>
    </Cell>
  );
};

GridPlansCell.propTypes = {
  children: PropTypes.any,
  backgroundColor: PropTypes.string,
  gridColumn: PropTypes.string,
  width: PropTypes.any.isRequired,
  cellPosition: PropTypes.oneOf([
    'leftTop',
    'rightTop',
    'leftTopSecound',
    'rightMiddle',
    'leftMiddle',
    'middleMiddle',
    'leftBottom',
    'middleBottom',
    'rightBottom',
  ]),
  alignItems: PropTypes.bool,
};

GridPlansCell.defaultProps = {
  children: {},
  backgroundColor: '',
  gridColumn: '',
  cellPosition: 'middleMiddle',
  alignItems: false,
};

export default withWindowResize(GridPlansCell);
