import styled from 'styled-components';

export const ButtonSearch = styled.a`
  background-color: ${props => props.theme.buttonSearch.background} !important;
  color: ${props => props.theme.buttonSearch.color} !important;
  :hover {
    background-color: ${props => props.theme.buttonSearch.backgroundHover} !important;
    color: ${props => props.theme.buttonSearch.colorHover} !important;
  }
`;
