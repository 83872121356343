import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
:root {
  font-size: 10px;
}

body {
  background-color: ${props => props.theme.background};
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  body * {
    font-family: 'Work Sans', sans-serif;
}
`;
