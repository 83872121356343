import React, { memo, Component } from 'react';
import PropTypes from 'prop-types';
import cookies from 'browser-cookies';
import 'purecss/build/grids-min.css';
import 'purecss/build/grids-responsive-min.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import { withTheme } from 'styled-components';
import classNames from 'classnames';

import FieldText from '../components/field-text';
import HeaderContainer from '../layout/components/header';
import CoachCardContainer from '../components/coachCard';
import ProfileModalContainer from '../profile/index';
import EmptyResult from '../layout/components/emptyResult';
import ModalInformation from '../layout/components/modalInformation';
import StepContainer from '../layout/components/steps';
import { ButtonSearch } from './styled';
import './index.css';
import Selected from '../components/selected';
import { VIRTUAL_ASSISTANT_DIR } from '../shared/constants/directories-constants';
import { httpClient } from '../shared/helpers/request-helper';
import {
  YEARS_EXPERIENCE_OPTIONS,
  ICF_CERTIFICATION_LEVEL,
} from '../shared/constants/search-field-constants';

const loader = <div className="loader">Loading...</div>;
const clientUrl = process.env.REACT_APP_CLIENT_URL || 'https://soar.com/';

const loadLabel = (coachs, theme) => {
  if (coachs.totalCount === 0) {
    return '';
  }
  if (typeof coachs.totalCount === 'string') {
    return coachs.totalCount;
  }
  return coachs.totalCount === 1
    ? theme.searchFields.foundMessageSingular || '1 Coach found'
    : `${coachs.totalCount} ${theme.searchFields.foundMessagePlural || 'Coaches found'}`;
};

const setCookie = (name, value, exp = 1) => {
  cookies.set(name, JSON.stringify(value), {
    expires: exp,
  });
};

const getCookie = name => {
  return cookies.get(name);
};

const defaultResult = {
  totalCount: 0,
  returnedCount: 0,
  items: [],
};

let cookie = '';

class SearchPage extends Component {
  state = {
    coachs: defaultResult,
    hasMore: true,
    isEmpty: false,
    isLoading: true,
    languagesAPI: [],
    industryAPI: [],
    icfTypeAPI: [],
    countriesAPI: [],
    pageNum: 1,
    languageCode: this.props.theme.searchFields.fields.find(i => i.field === 'language')
      .defaultValue,
    clientTypes: this.props.theme.searchFields.fields.find(i => i.field === 'clientType')
      .defaultValue,
    industryCode: this.props.theme.searchFields.fields.find(i => i.field === 'industry')
      .defaultValue,
    vaYearsExperienceCode: this.props.theme.searchFields.fields.find(
      i => i.field === 'vaYearsExperience'
    ).defaultValue,
    vaEnglishCert: this.props.theme.searchFields.fields.find(i => i.field === 'vaEnglishCert')
      .defaultValue,
    coachName: this.props.theme.searchFields.fields.find(i => i.field === 'name').defaultValue,
    icfCertLevel: this.props.theme.searchFields.fields.find(i => i.field === 'icfCertLevel')
      .defaultValue,
    countryCode: this.props.theme.searchFields.fields.find(i => i.field === 'country').defaultValue,
    searchDirectory: this.props.theme.searchDirectory,
    isPaid: this.props.theme.isPaid,
    show: false,
    showInfo: false,
    profile: {},
    width: window.innerWidth,
    isLoadingProfile: true,
  };

  componentDidMount() {
    const cookieInfo = getCookie('coachOnlineInformation');
    if (!cookieInfo && this.props.theme.showInfoBox !== false) this.setState({ showInfo: true });

    const fetchIndustry = async () => {
      let { data: value } = await httpClient.get(
        `${process.env.REACT_APP_SOAR_API_HOST}/Authority/icf/industries`
      );
      value = value.map(({ code, name }) => ({ value: code, label: name }));
      value.unshift({ value: null, label: 'No Preference' });
      this.setState({ industryAPI: value });
      localStorage.setItem('coachOnlineIndustry', JSON.stringify(value));
    };

    const fetchClientTypes = async () => {
      let { data: value } = await httpClient.get(
        `${process.env.REACT_APP_SOAR_API_HOST}/Authority/icf/clientTypes`
      );
      value = value.map(({ code, name }) => ({ value: code, label: name }));
      value.unshift({ value: null, label: 'No Preference' });
      this.setState({ icfTypeAPI: value });
      localStorage.setItem('coachOnlineClientType', JSON.stringify(value));
    };

    const fetchLanguage = async () => {
      let { data: value } = await httpClient.get(
        `${process.env.REACT_APP_SOAR_API_HOST}/Authority/icf/languages`
      );
      value = value.map(({ code, name }) => ({ value: code, label: name }));
      value.unshift({ value: null, label: 'No Preference' });
      this.setState({ languagesAPI: value });
      localStorage.setItem('coachOnlineLanguage', JSON.stringify(value));
    };

    const fetchCountries = async () => {
      let { data: value } = await httpClient.get(
        `${process.env.REACT_APP_SOAR_API_HOST}/Authority/countries`
      );
      value = value.map(({ id, name }) => ({ value: id, label: name }));
      value.unshift({ value: null, label: 'No Preference' });
      this.setState({ countriesAPI: value });
      localStorage.setItem('coachOnlineCountries', JSON.stringify(value));
    };

    const lastUpdateReq = getCookie('coachSearchLastReq');
    if (!lastUpdateReq) {
      localStorage.removeItem('coachOnlineIndustry');
      localStorage.removeItem('coachOnlineClientType');
      localStorage.removeItem('coachOnlineLanguage');
      localStorage.removeItem('coachOnlineCountries');
      fetchClientTypes();
      fetchIndustry();
      fetchLanguage();
      fetchCountries();
      setCookie('coachSearchLastReq', 'true', 7);
    } else {
      cookie = localStorage.getItem('coachOnlineIndustry');
      if (cookie) {
        this.setState({ industryAPI: JSON.parse(cookie) });
        cookie = '';
      }
      fetchClientTypes();
      cookie = localStorage.getItem('coachOnlineLanguage');
      if (cookie) {
        this.setState({ languagesAPI: JSON.parse(cookie) });
        cookie = '';
      }
      cookie = localStorage.getItem('coachOnlineCountries');
      if (cookie) {
        this.setState({ countriesAPI: JSON.parse(cookie) });
        cookie = '';
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.addEventListener('resize', this.updateDimensions);
    this.fetchMyAPI();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  // eslint-disable-next-line react/sort-comp
  fetchMyAPI() {
    this.setState({
      hasMore: true,
      isEmpty: false,
      isLoading: true,
    });
    const {
      industryCode,
      languageCode,
      clientTypes,
      coachName,
      coachs,
      pageNum,
      searchDirectory,
      isPaid,
      vaYearsExperienceCode,
      vaEnglishCert,
      icfCertLevel,
      countryCode,
    } = this.state;

    const data = { searchDirectories: [searchDirectory], isPaid };
    if (!!industryCode && industryCode.length) data.industries = [industryCode];
    if (!!languageCode && languageCode.length) data.languages = [languageCode];
    if (vaYearsExperienceCode) data.vaYearsExperience = [vaYearsExperienceCode];
    if (vaEnglishCert) data.vaEnglishCert = vaEnglishCert;
    if (!!clientTypes && clientTypes.length) data.clientTypes = [clientTypes];
    if (!!icfCertLevel && icfCertLevel.length) data.icfCertLevel = [icfCertLevel];
    if (!!countryCode && countryCode.length) data.countryCode = [countryCode];
    if (coachName) data.coachName = coachName;

    const getUsers = 'Search/coachDirectory';
    const getOnlyCoachUsers = 'Search/allUsersDirectory';
    // VirtualAssistant users are not registered as coache
    const pathAPI = searchDirectory === VIRTUAL_ASSISTANT_DIR ? getOnlyCoachUsers : getUsers;

    httpClient.post(`/${pathAPI}?pageNum=${pageNum}&itemsPerPage=18`, data).then(response => {
      const { data: responseData } = response;
      responseData.items = [...coachs.items, ...responseData.items];

      if (responseData.totalCount <= responseData.items.length) {
        this.setState({ hasMore: false });
        if (responseData.totalCount < 1) {
          this.setState({ isEmpty: true });
        }
      } else {
        this.setState({ pageNum: pageNum + 1 });
      }

      if (Object.keys(data).length === 0) {
        response.data.totalCount = 'Showing the last active Coaches.';
        if (pageNum === 1) {
          responseData.items.sort(() => 0.5 - Math.random());
        }
      }

      this.setState({ coachs: { ...coachs, ...responseData }, isLoading: false });
    });
  }

  async buttonAction() {
    this.setState({
      hasMore: false,
      coachs: defaultResult,
      isLoading: true,
      isEmpty: false,
      pageNum: 1,
    });
    const {
      industryCode,
      languageCode,
      clientTypes,
      coachName,
      searchDirectory,
      isPaid,
      vaYearsExperienceCode,
      vaEnglishCert,
      icfCertLevel,
      countryCode,
    } = this.state;

    const data = { searchDirectories: [searchDirectory], isPaid };
    if (industryCode !== null && industryCode.length) {
      data.industries = [industryCode];
    }
    if (languageCode !== null && languageCode.length) {
      data.languages = [languageCode];
    }
    if (clientTypes !== null && clientTypes.length) {
      data.clientTypes = [clientTypes];
    }
    if (coachName) {
      data.coachName = coachName;
    }
    if (vaYearsExperienceCode !== null) {
      data.vaYearsExperience = vaYearsExperienceCode;
    }
    if (vaEnglishCert) {
      data.vaEnglishCert = vaEnglishCert;
    }
    if (icfCertLevel !== null && icfCertLevel.length) {
      data.icfCertLevel = icfCertLevel.toString();
    }
    if (countryCode !== null && countryCode.length) {
      data.countryCode = countryCode.toString();
    }
    const getUsers = 'Search/coachDirectory';
    const getOnlyCoachUsers = 'Search/allUsersDirectory';
    // VirtualAssistant users are not registered as coache
    const pathAPI = searchDirectory === VIRTUAL_ASSISTANT_DIR ? getOnlyCoachUsers : getUsers;

    const response = await httpClient.post(
      `${process.env.REACT_APP_SOAR_API_HOST}/${pathAPI}?pageNum=1&itemsPerPage=18`,
      data
    );

    if (response.data.totalCount <= 18) {
      this.setState({ hasMore: false });
      if (response.data.totalCount < 1) {
        this.setState({ isEmpty: true });
      }
    } else {
      this.setState({
        pageNum: 2,
        hasMore: true,
      });
    }
    if (Object.keys(data).length === 0) {
      response.data.totalCount = 'Showing the last active Coaches.';
    }

    this.setState({ coachs: response.data, isLoading: false });
  }

  // eslint-disable-next-line class-methods-use-this
  splitFunction(string, obj) {
    let tmp = '';
    let valueReturn = '';
    const split = string.split(',');
    for (let key = 0; key <= split.length; key++) {
      tmp = obj.find(options => options.value === split[key]);
      if (tmp) {
        valueReturn += `${tmp.label}, `;
      }
    }

    return valueReturn.slice(0, -2) || String(string).replace(/,/g, ', ');
  }

  handleIndustryChange(e) {
    const { value } = e;
    this.setState({ industryCode: value });
  }

  handleLanguageChange(e) {
    const { value } = e;
    this.setState({ languageCode: value });
  }

  handleVaYearsExperienceCode(e) {
    const { value } = e;
    this.setState({ vaYearsExperienceCode: value });
  }

  handleVaEnglishCert(e) {
    const {
      target: { value },
    } = e;
    this.setState({ vaEnglishCert: value });
  }

  handleCoachNameChange(e) {
    const {
      target: { value },
    } = e;
    this.setState({ coachName: value });
  }

  handleCountryChange(e) {
    const { value } = e;
    this.setState({ countryCode: value });
  }

  handleClientTypes(e) {
    const { value } = e;
    this.setState({ clientTypes: value });
  }

  handleCloseAlert(event) {
    event.preventDefault();
    cookies.set('coachOnlineInformation', 'true', {
      expires: 2,
    });
    this.setState({ showInfo: false });
  }

  handleIcfCertLevel(e) {
    const { value } = e;
    this.setState({ icfCertLevel: value });
  }

  handleShow = async coachId => {
    this.setState({ show: true, isLoadingProfile: true });
    const { data: user } = await httpClient.get(
      `${process.env.REACT_APP_SOAR_API_HOST}/user/${coachId}`
    );
    this.setState({ profile: user, isLoadingProfile: false });
  };

  handleClose(event) {
    event.preventDefault();
    this.setState({ show: false });
  }

  render() {
    const {
      coachs,
      hasMore,
      industryAPI,
      icfTypeAPI,
      languagesAPI,
      countriesAPI,
      isLoading,
      show,
      showInfo,
      profile,
      isLoadingProfile,
      width,
      industryCode,
      clientTypes,
      languageCode,
      coachName,
      isEmpty,
      vaYearsExperienceCode,
      vaEnglishCert,
      icfCertLevel,
      countryCode,
    } = this.state;
    const { theme } = this.props;
    const { steps } = theme;
    const industriesValue = industryAPI.find(industry => industry.value === industryCode);
    const languageValue = languagesAPI.find(language => language.value === languageCode);
    const vaYearsExperienceValue = YEARS_EXPERIENCE_OPTIONS.find(
      vaYearsExperience => vaYearsExperience.value === vaYearsExperienceCode
    );
    const clientTypeValue = icfTypeAPI.find(icfType => icfType.value === clientTypes);
    const icfCertLevelValue = ICF_CERTIFICATION_LEVEL.find(
      icfCertification => icfCertification.value === icfCertLevel
    );
    const countriesValue = countriesAPI.find(country => country.value === countryCode);
    const imgLogo =
      width < 500 ? '/images/powered-by-soar-mobile.png' : '/images/powered-by-soar.png';

    const { fieldsPerLine } = theme.searchFields;
    const fields = theme.searchFields.fields
      .filter(field => field.enabled)
      .map(field => {
        switch (field.field) {
          case 'industry':
            return {
              ...field,
              component: Selected,
              attributes: {
                id: 'industry',
                theme,
                className: 'search-fields-item',
                label: theme.theme === 'icf' ? 'Type of Training:' : 'Industry/Sector:',
                options: industryAPI,
                value: industriesValue,
                onChange: e => this.handleIndustryChange(e),
                img: theme.theme === 'icf' ? '/images/folder.svg' : '/images/industry.svg',
              },
            };
          case 'testField2':
            return {
              ...field,
              component: Selected,
              attributes: {
                id: 'testField2',
                theme,
                className: 'search-fields-item',
                label: 'Test Field 2:',
                options: [
                  { value: '01', label: 'Abc' },
                  { value: '02', label: 'Def' },
                ],
                onChange: () => {},
                img: '/images/industry.svg',
              },
            };
          case 'testField3':
            return {
              ...field,
              component: Selected,
              attributes: {
                id: 'testField3',
                theme,
                className: 'search-fields-item',
                label: 'Test Field 3:',
                options: [
                  { value: '01', label: 'Abc' },
                  { value: '02', label: 'Def' },
                ],
                onChange: () => {},
                img: '/images/industry.svg',
              },
            };
          case 'testField1':
            return {
              ...field,
              component: Selected,
              attributes: {
                id: 'testField1',
                theme,
                className: 'search-fields-item',
                label: 'Test Field 1:',
                options: [
                  { value: '01', label: 'Abc' },
                  { value: '02', label: 'Def' },
                ],
                onChange: () => {},
                img: '/images/industry.svg',
              },
            };
          case 'clientType':
            return {
              ...field,
              component: Selected,
              attributes: {
                id: 'clientType',
                theme,
                className: 'search-fields-item',
                label: 'Current Role:',
                options: icfTypeAPI,
                value: clientTypeValue,
                onChange: e => this.handleClientTypes(e),
                img: '/images/role.svg',
              },
            };
          case 'language':
            return {
              ...field,
              component: Selected,
              attributes: {
                id: 'language',
                theme,
                className: 'search-fields-item',
                label: 'Language:',
                options: languagesAPI,
                value: languageValue,
                onChange: e => this.handleLanguageChange(e),
                img: '/images/language.svg',
              },
            };
          case 'vaYearsExperience':
            return {
              ...field,
              component: Selected,
              attributes: {
                id: 'vaYearsExperience',
                theme,
                className: 'search-fields-item',
                label: 'Years of experience:',
                options: YEARS_EXPERIENCE_OPTIONS,
                value: vaYearsExperienceValue,
                onChange: e => this.handleVaYearsExperienceCode(e),
                img: '/images/star-solid.svg',
              },
            };
          case 'vaEnglishCert':
            return {
              ...field,
              component: FieldText,
              attributes: {
                id: 'vaEnglishCert',
                theme,
                className: 'search-fields-item',
                label: 'English certification:',
                placeholder: 'Certificate Name',
                value: vaEnglishCert,
                onChange: e => this.handleVaEnglishCert(e),
                img: '/images/certificate-solid.svg',
              },
            };
          case 'icfCertLevel':
            return {
              ...field,
              component: Selected,
              attributes: {
                id: 'icfCertLevel',
                theme,
                className: 'search-fields-item',
                label: 'ICF Credentials',
                options: ICF_CERTIFICATION_LEVEL,
                value: icfCertLevelValue,
                onChange: e => this.handleIcfCertLevel(e),
                img: '/images/choices.svg',
              },
            };
          case 'country':
            return {
              ...field,
              component: Selected,
              attributes: {
                id: 'country',
                theme,
                className: 'search-fields-item',
                label: 'Country:',
                options: countriesAPI,
                value: countriesValue,
                onChange: e => this.handleCountryChange(e),
                img: '/images/role.svg',
              },
            };
          case 'name':
            return {
              ...field,
              component: FieldText,
              attributes: {
                id: 'name',
                theme,
                className: 'search-fields-item',
                label: 'Search by Name',
                placeholder: field.placeholder || 'Coach Name',
                value: coachName,
                onChange: e => this.handleCoachNameChange(e),
              },
            };
          default:
            return false;
        }
      })
      .filter(field => field);
    const fieldsGrid = fieldsPerLine > fields.length ? fields.length : fieldsPerLine;

    fields.push({
      component: ButtonSearch,
      attributes: {
        className:
          'item button button-medium button-blue button-rounded coachSearch_button search-btn',
        onClick: () => this.buttonAction(),
        children: 'Search',
      },
    });
    const containerCards = classNames('pure-u-1', {
      'coachCardsContainer': theme.theme === 'wbecs',
    });
    const containerSteps = classNames('pure-1 divFlex divStep', {
      'stepContainer': theme.theme === 'wbecs',
    });
    return (
      <InfiniteScroll
        style={{ overflow: 'unset' }}
        dataLength={coachs.items.length}
        next={() => this.fetchMyAPI()}
        hasMore={hasMore}
        scrollableTarget="#soar-cards"
        endMessage={<></>}
      >
        <HeaderContainer />
        <section>
          <div className="app-content pure-g">
            <div className={containerSteps}>
              <StepContainer
                title={steps[0].title}
                subTitle={steps[0].subTitle}
                text={steps[0].text}
                margin
                step={1}
                className="divStep-item"
              />
              <StepContainer
                title={steps[1].title}
                subTitle={steps[1].subTitle}
                text={steps[1].text}
                margin
                step={2}
                className="divStep-item"
              />
              <StepContainer
                title={steps[2].title}
                subTitle={steps[2].subTitle}
                text={steps[2].text}
                step={3}
                className="divStep-item"
              />
            </div>
            {theme.promoCode.promoCode && (
              <div className="search-page__link-promo-code">
                {"*Don't have a code? "}
                <a href={theme.promoCode.path}>Click here.</a>
              </div>
            )}
            {!!(fields.length - 1) && (
              <>
                <div className="pure-g">
                  <h2 className="section_title w-100">
                    {theme.searchFields.headLine || 'Find Your Coach'}
                    <a href={clientUrl}>
                      <img src={imgLogo} className="image_powered" alt="SOAR" />
                    </a>
                  </h2>
                </div>
                <div className="divSearch">
                  <div className="search-fields-wrapper">
                    {fields.map(field => {
                      return (
                        !!field && (
                          <field.component
                            key={`${String(field.field)}-${String(field.enabled)}`}
                            {...{
                              ...field.attributes,
                              className: `search-fields-item-${fieldsGrid} ${field.attributes.className}`,
                            }}
                          />
                        )
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
        <section id="soar-cards">
          <div className="app-content pb-80">
            <div className="pure-g">
              <h2 className="section_title recently">{loadLabel(coachs, theme)}</h2>
            </div>
            <div className="pure-g coach-cards">
              <div className={containerCards}>
                {coachs.items.map(item => (
                  <CoachCardContainer
                    key={item.id}
                    item={item.id}
                    img={
                      item.profilePictUrl && item.profilePictUrl !== ''
                        ? item.profilePictUrl.replace('http://', 'https://')
                        : `https://i1.wp.com/cdn.auth0.com/avatars/${
                            item.name ? item.name.substring(0, 1).toLowerCase() : 's'
                          }.png`
                    }
                    alt={item.vanityUrl}
                    name={item.name}
                    location={
                      `${item.locality ? item.locality : ''}` +
                      `${item.region ? `, ${item.region}` : ''}`
                    }
                    industry={this.splitFunction(item.icfIndustries, industryAPI)}
                    language={this.splitFunction(item.icfLanguages, languagesAPI)}
                    handleShow={() => this.handleShow(item.id)}
                    theme={theme}
                  />
                ))}
                {isEmpty && <EmptyResult />}
              </div>
            </div>
          </div>
        </section>
        {isLoading && loader}
        <ModalInformation show={showInfo} handleClose={e => this.handleCloseAlert(e)} />
        <ProfileModalContainer
          show={show}
          profile={profile}
          isLoadingProfile={isLoadingProfile}
          splitFunction={this.splitFunction}
          languagesAPI={languagesAPI}
          industryAPI={industryAPI}
          handleClose={e => this.handleClose(e)}
          buttonChooseCoach={theme.coachCard.buttonChooseCoach}
        />
      </InfiniteScroll>
    );
  }
}

SearchPage.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default memo(withTheme(SearchPage));
