import styled from 'styled-components';

export const Icon = styled.div`
  line-height: 1;
  font-weight: ${props => props.secondary && 900};
  &::after {
    color: ${props => props.color || '#a0a6cc'};
    font-family: 'Font Awesome 5 Pro';
    content: ${props => `'\\${props.unicode}'`};
  }
`;
