import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '../../Grid';
import Header from '../../Grid/Cell/Header';
import Body from '..';

const GridIntegrations = () => {
  const integrations = useSelector(state => state.pricePlans.plansAccess.integrations);
  return (
    <Grid isLastGrid>
      <Header cellPosition="middleMiddle" gridColumns={2}>Integrations</Header>
      <Body items={integrations} alignItems isLastGrid />
    </Grid>
  );
};

export default GridIntegrations;
