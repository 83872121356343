import styled from 'styled-components';
import { Alert } from 'react-bootstrap';

export const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const WrapperDescription = styled.div`
  padding: 1.7rem 2.2rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
  background-color: #595d6f;
  color: #ffffff;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
`;

export const PlanName = styled.div`
  font-weight: 600;
`;

export const Billed = styled.div`
  justify-self: center;
`;

export const Price = styled.div`
  justify-self: end;
`;

export const Label = styled.label`
  font-weight: 500;
  display: flex;
`;

export const InputEmail = styled.input.attrs(() => ({
  type: "text"
}))`
  width: 100%;
  border: none;
  margin-left: 1.5rem;
  :focus {
    outline: none;
  }
`;

export const WrapperInput = styled.div`
  margin-top: 2rem;
  padding: 1.542rem 2.2rem;
  border-style: none;
  background-color: #ffffff;
  will-change: opacity, transform;
  border-radius: 0.4rem;
`;

export const Danger = styled(Alert).attrs(() => ({
  variant: "danger"
}))`
  margin-top: 2rem;
`;

export const Button = styled.button.attrs(() => ({
  type: "submit"
}))`
  width: 15.6rem;
  margin-top: 2rem;
  background-color: #7909ea;
  color: #ffffff;
  padding: 1.5rem 1.1rem;
  border-radius: 2.4rem;
  line-height: 1;
  align-self: center;
  font-size: 1.43rem;
  font-weight: bold;
  border: none;

  :disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;
