import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './button-primary.scss';

const CardPrimary = ({ children, link, fluid }) => {
  const buttonClassNames = classNames('button-primary', {
    'button-primary__fluid': fluid,
  });

  return (
    <a href={link} className={buttonClassNames}>
      {children}
    </a>
  );
};

CardPrimary.propTypes = {
  children: PropTypes.element,
  link: PropTypes.string,
  fluid: PropTypes.bool,
};

CardPrimary.defaultProps = {
  children: '',
  link: '',
  fluid: false,
};

export default memo(CardPrimary);
