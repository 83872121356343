import React from 'react';
import './emptyResult.css';

const EmptyResult = () => (
  <div className="app-content">
    <div className="pure-g">
      <div className="empty__content">Try modifying the options above and searching again.</div>
    </div>
  </div>
);
export default EmptyResult;
