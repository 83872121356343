import React, { memo } from 'react';
import { withTheme } from 'styled-components';

import { Container, Title, SubtTitle, Text, Content } from './styled';
import './index.css';

const Step = props => {
  const { title, subTitle, text, margin, step, theme } = props;

  return (
    <Container
      step={step}
      margin={margin}
      stepImage={`/assets/${theme.directory}/${theme.stepImage}`}
    >
      <Content>
        <>
          <Title>{title}</Title>
          <p>
            <SubtTitle>{subTitle}</SubtTitle>
          </p>
          <Text>{text}</Text>
        </>
      </Content>
    </Container>
  );
};

export default memo(withTheme(Step));
