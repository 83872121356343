import React from 'react';
import Header from '../layout/components/header/default/header';
import 'purecss/build/grids-min.css';
import 'purecss/build/grids-responsive-min.css';

import './index.css';

const FormICF = () => {
  return (
    <>
      <Header />
      <iframe
        title="contact-form"
        src="https://form.jotform.com/203033930241138"
        id="iframe-icf-partnership-form"
        scrolling="no"
        frameBorder="0"
      />
      <div className="app-content home__footer">
        <div className="pure-g">
          <div className="home__block">
            <h2>Contact</h2>
            <div className="home__underLine" />
            <h3>Mark Ruth</h3>
            <p>
              <span>Director of Strategic Partnerships</span>
              <br />
              <span>International Coaching Federation</span>
              <br />
              <a href="mailto:mark.ruth@coachfederation.org">
                <strong>mark.ruth@coachfederation.org</strong>
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormICF;
