import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Logo } from './styled';

const SiteHeader = ({ theme }) => (
  <header style={theme.headerContainer ?? null}>
    <div style={theme.mainContainer ?? null}>
      <a href={theme.logoLinkUrl ?? '/'}>
        <Logo style={theme.logoStyle} src={`/assets/${theme.directory}/${theme.logo}`} alt="SOAR" />
      </a>
    </div>
  </header>
);

SiteHeader.propTypes = {
  theme: PropTypes.object,
};

SiteHeader.defaultProps = {
  theme: {},
};

export default withTheme(SiteHeader);
