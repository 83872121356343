import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from './styles';

const GridPlans = ({ children, ...props }) => <Grid {...props}>{children}</Grid>;

GridPlans.propTypes = {
  children: PropTypes.any,
};

GridPlans.defaultProps = {
  children: {},
};

export default GridPlans;
