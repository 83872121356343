import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  gap: 1rem;
`;

export const GridCalendar = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: min-content auto;
`;

export const ImgCalendar = styled.img`
  height: 2.2rem;
  min-width: 2.2rem;
  object-fit: contain;
`;

export const LabelCalendar = styled.div``;
