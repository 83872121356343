import styled from 'styled-components';

export const InputEmailChoose = styled.input`
  padding-left: 1.3rem;
  align-items: center;
  border-color: ${props => props.borderColor || 'hsl(0,0%,80%)'};
  border-radius: 0.4rem;
  border-style: solid;
  border-width: 0.1rem;
  min-height: 3.8rem;
  min-width: 28rem;
  position: relative;
  box-sizing: border-box;
`;

export const InputEmailChooseError = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  color: #dc3545;
`;

export const ButtonChoose = styled.a`
  white-space: normal;
  text-transform: none;
  text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  letter-spacing: -0.01rem;
  position: relative;
  background-color: ${props => props.background || '#d4d9f7'};
  color: ${props => props.color || '#5f6275'} !important;
  width: ${props => props.width || '15.517rem'};
  justify-content: center;
  height: 42px;
  border-radius: 4px;
  padding: 0px 10px;
  font-size: 15px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  text-align: center;
  cursor: pointer;

  :hover {
    cursor: ${props => (props.disabled ? 'default !important' : 'pointer')};
  }
`;
