import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import GridHeader from './GridHeader';
import GridFeatures from './GridBody/Features';
import GridSupport from './GridBody/Support';
import GridIntegrations from './GridBody/Integrations';
import { setPlansAccess, setPlansHeader } from '../actions/price-plans-actions';

import { Container } from './styles';
import Subscription from './SectionSubscription';
import ThankYou from './ThankYou';

const PricePage = ({ theme }) => {
  const dispatch = useDispatch();
  const period = useSelector(state => state.pricePlans.period);
  const successMessageSubscription = useSelector(state => state.pricePlans.successMessageSubscription);
  

  useEffect(() => {
    dispatch(setPlansHeader(theme.pricePlans.header));
    dispatch(setPlansAccess(theme.pricePlans.body[period]));
  }, [dispatch, period, theme.pricePlans.body, theme.pricePlans.header]);

  return (
    <Container>
      {successMessageSubscription ? (
        <ThankYou />
      )
      : (
        <>

          <Subscription />
          <GridHeader />
          <GridFeatures />
          <GridSupport />
          <GridIntegrations />
        </>
      )}
    </Container>
  );
};

PricePage.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(PricePage);
