import React from 'react';
import { withTheme } from 'styled-components';

import Steps from './default';
import StepsWbecs from './wbecs';

const HeaderContainer = props => {
  const { title, subTitle, text, margin, step, theme } = props;
  return (
    <>
      {!theme ||
        (theme.theme !== 'wbecs' && (
          <Steps title={title} subTitle={subTitle} text={text} margin={margin} step={step} />
        ))}
      {theme.theme === 'wbecs' && (
        <StepsWbecs title={title} subTitle={subTitle} text={text} margin={margin} step={step} />
      )}
    </>
  );
};

export default withTheme(HeaderContainer);
