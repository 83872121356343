import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Cell from '../Grid/Cell';
import { MEDIUM } from '../../../shared/constants/width-constants';
import withWindowResize from '../../../shared/hocs/withWindowResize';
import { Icon } from '../../../shared/components/FontAwesomeIcon/styles';

const V = () => <Icon unicode="f00c" solid />;
const X = () => <Icon unicode="f00d" solid />;

const GridBody = ({ items, isLastGrid, alignItems, width }) => {
  const plan = useSelector(state => state.pricePlans.plan);
  return items.map(({ name: { value }, basic, premium }) => (
    <React.Fragment key={value}>
      <Cell cellPosition={isLastGrid ? 'leftBottom' : 'leftMiddle'}>{value}</Cell>

      {/* {(width.size > MEDIUM || plan === 'basic') && (
        <Cell alignItems="center" cellPosition={isLastGrid ? 'middleBottom' : 'middleMiddle'}>
          {basic.obs || (basic.value ? <V /> : <X />)}
        </Cell>
      )} CARD  WEB-2271*/}

      {(width.size > MEDIUM || plan === 'premium') && (
        <Cell alignItems={alignItems} cellPosition={isLastGrid ? 'rightBottom' : 'rightMiddle'}>
          {premium.obs || (premium.value ? <V /> : <X />)}
        </Cell>
      )}
    </React.Fragment>
  ));
};

GridBody.propTypes = {
  items: PropTypes.array.isRequired,
  isLastGrid: PropTypes.bool,
  width: PropTypes.any.isRequired,
  alignItems: PropTypes.bool,
};

GridBody.defaultProps = {
  isLastGrid: false,
  alignItems: false,
};

export default withWindowResize(GridBody);
