import React from 'react';
import PropTypes from 'prop-types';

import Section from '../section-primary';
import { Title, SubTitle, Description } from './styled';
import './header.scss';

const Header = ({ title, subtitle, paragraph, imagePath, logoPath, logoLink, showImage }) => (
  <Section>
    <header className="header">
      <div className="header__logo">
        <a href={logoLink}>
          <img width="120" src={logoPath} alt="SOAR" className="brand-image" />
        </a>
        <div className="header__rectangle" />
      </div>
      <div className={showImage && 'header__grid'}>
        <div className="header__grid__item-1">
          {title && <Title className="header__title">{title}</Title>}
          {subtitle && <SubTitle className="header__subtitle">{subtitle}</SubTitle>}
          {paragraph && <Description className="header__content">{paragraph}</Description>}
        </div>
        {showImage && (
          <div className="header__grid__item-2 header__image_section">
            <img src={imagePath} className="header__image" alt="meeting" />
          </div>
        )}
      </div>
    </header>
  </Section>
);

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  paragraph: PropTypes.string,
  logoPath: PropTypes.string,
  logoLink: PropTypes.string,
  imagePath: PropTypes.string,
  showImage: PropTypes.bool,
};

Header.defaultProps = {
  title: false,
  subtitle: false,
  paragraph: false,
  logoPath: '/images/icf-logo.png',
  logoLink: '/',
  imagePath: '/images/hero-illustration.png',
  showImage: true,
};

export default Header;
