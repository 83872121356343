import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, GridCalendar, ImgCalendar, LabelCalendar } from './styles';
import withWindowResize from '../../../../../shared/hocs/withWindowResize';
import { LARGE } from '../../../../../shared/constants/width-constants';

const GoogleOutlookCalendar = ({ width }) => {
  const style = {
    flexDirection: 'column',
  };

  return (
    <Wrapper {...style}>
      <GridCalendar>
        <ImgCalendar src="/images/pricePlans/google-calendar.png" alt="Google calendar" />
        <LabelCalendar>Google Calendar and</LabelCalendar>
      </GridCalendar>
      <GridCalendar>
        <ImgCalendar src="/images/pricePlans/outlook-calendar.png" alt="Outlook Calendar" />
        <LabelCalendar>Outlook Calendar</LabelCalendar>
      </GridCalendar>
      <GridCalendar>
        <ImgCalendar src="/images/pricePlans/soar-logo.png" alt="Uplify Calendar" />
        <LabelCalendar>Coach Uplift Weekly</LabelCalendar>
      </GridCalendar>
    </Wrapper>
  );
};

GoogleOutlookCalendar.propTypes = {
  width: PropTypes.any.isRequired,
};

export default withWindowResize(GoogleOutlookCalendar);
