/* eslint-disable no-nested-ternary */
import React from 'react';
import PropType from 'prop-types';

import { Container, Content, Header, Line, InfoCoach, Button, Icon, Description, CoachImageBlock, CoachImageView } from './styled';

const CardCoach = ({ name, location, industry, language, img, alt, handleShow }) => {
  return (
    <Container>
      <CoachImageBlock coachImage={img} />
      <CoachImageView coachImage={img} />
      <Content>
        <Header>{name}</Header>
        <Line />
        <div>
          <InfoCoach>
            <>
              <Icon marginTop="0" marginBottom="0" marginRight="0" marginLeft="0">
                <img src="/images/icon_pin_wbecs.svg" alt="location" />
              </Icon>
              <Description marginLeft="14">{location}</Description>
            </>
          </InfoCoach>
          <InfoCoach>
            <>
              <Icon marginTop="0" marginBottom="0" marginRight="0" marginLeft="0">
                <img src="/images/icon_building_wbec.svg" alt="industry" />
              </Icon>
              <Description marginLeft="14">{industry}</Description>
            </>
          </InfoCoach>
          <InfoCoach>
            <>
              <Icon marginTop="0" marginBottom="0" marginRight="0" marginLeft="3">
                <img src="/images/icon_language_wbecs.svg" alt="language" />
              </Icon>
              <Description marginLeft="14">{language}</Description>
            </>
          </InfoCoach>
        </div>
        <Button onClick={handleShow}>
          VIEW PROFILE
          <i className="fas fa-arrow-circle-right" />
        </Button>
      </Content>
    </Container>
  );
};

CardCoach.propTypes = {
  name: PropType.string,
  location: PropType.string,
  industry: PropType.string,
  language: PropType.string,
  img: PropType.string,
  alt: PropType.string,
  handleShow: PropType.func,
};

CardCoach.defaultProps = {
  name: '',
  location: '',
  industry: '',
  language: '',
  img: '',
  alt: '',
  handleShow: () => {},
};

export default CardCoach;
