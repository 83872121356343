import React from 'react';
import Header from '../shared/components/header';
import 'purecss/build/grids-min.css';
import 'purecss/build/grids-responsive-min.css';

import './index.css';

const FormICFRequestCode = () => {
  const paragraph =
    'During the pilot phase for ICF Coaches for Good, a limited number of access codes are available through our partner organizations and participating coaches. To learn more about the benefits of coaching and be notified about new developments with ICF Coaches for Good, complete the form below.';

  return (
    <>
      <Header
        title="Coaches for Good"
        subtitle="Pro-Bono Coaching Available "
        paragraph={paragraph}
        showImage={false}
      />

      <iframe
        title="icf-request-code"
        src="https://gm204.infusionsoft.app/app/form/d4aae5e12b26a9a2a66d36816d727b21?iFrame=true&cookieUUID=b9c3d856-ac72-4676-83d9-8681b4984eb9"
        id="iframe-icf-form-request-code"
        scrolling="no"
        frameBorder="0"
      />

      <div className="app-content">
        <div className="pure-g">
          <div className="icf-text">
            <p>
              Pursuant to the Canadian Anti-Spam Law (CASL) and the European Union General Data
              Protection Regulation (GDPR), ICF and SOAR are seeking your express consent to have
              your electronic personal information stored in our system and to receive information
              regarding the International Coaching Federation, SOAR and professional coaching. If
              you decide that you don’t want to receive content any longer, please note that we may
              still be required to send you emails regarding factual, transactional and/or servicing
              information in connection with any products or services that we provide or have
              provided to you. By clicking “Submit” above, you give your consent to have your
              electronic information stored by ICF and to receive emails from ICF and SOAR regarding
              the International Coaching Federation and professional coaching.
            </p>
            <p>
              To provide a more personalized experience online, your information may be shared with
              third parties (e.g., Facebook, Twitter, LinkedIn, Instagram) for the specific purposes
              of online user profiling and targeted marketing.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormICFRequestCode;
