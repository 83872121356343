import React from 'react';
import { useSelector } from 'react-redux';

import { Wrapper, Box, H1, H2, Img, Description, Button } from './styles';

const ThankYou = () => {
  const fieldEmailIsNewUser = useSelector(state => state.pricePlans.fieldEmailIsNewUser);
  const fieldEmail = useSelector(state => state.pricePlans.fieldEmail);
  const link = `${process.env.REACT_APP_APP_URL}/signup?login=${!fieldEmailIsNewUser}&email=${fieldEmail}`;
  return (
    <Wrapper>
      <Box>
        <H1>You’re all set!</H1>
        <H2>Thanks for subscribing to <b>ICF Business.</b></H2>
        <Img src="/images/pricePlans/thank-you-page.png" alt="Thank you" />
        <Description>
          Start exploring the Coach's platform. 
          Make sure you fill in your profile and schedule information.
        </Description>
        <Button href={link}>
          Go to your Dashboard
        </Button>
      </Box>
    </Wrapper>
  );
};

export default ThankYou;
