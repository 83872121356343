import { compress, decompress } from 'lzutf8';

export const invertString = string => {
  let invertArray = string.split('');
  invertArray = invertArray.reverse();

  return invertArray.join('');
};

export const crypt = value => {
  const text = invertString(value);

  let compressed = compress(text, { outputEncoding: 'Base64' });
  compressed = invertString(compressed);
  compressed = compressed.replace('/', '@');

  return compressed;
};

export const decodeBase64 = base64 => {
  return decompress(base64, { inputEncoding: 'Base64' });
};

export const decodeHash = (hash, decodeFunction) => {
  let resolvedHash = invertString(hash);
  resolvedHash = resolvedHash.replace('@', '/');
  resolvedHash = decodeFunction(resolvedHash);
  return invertString(resolvedHash).split('?');
};
