import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(2, min-content) auto min-content;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  line-height: 1.15;
`;

export const Name = styled.div`
  font-size: 2rem;
  color: #5c5c5e;
`;

export const WrapperPrice = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.3rem;
  margin-top: 0.8rem;
`;

export const Price = styled.div`
  font-size: 2.5rem;
`;

export const Currency = styled.div`
  font-size: 1.2rem;
`;

export const Description = styled.div`
  font-size: 1.3rem;
  margin-top: 1.1rem;
  display: flex;
  text-align: center;
`;

export const Link = styled.a`
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  padding: 1.2rem 1.8rem;
  background-color: #263692;
  color: #ffffff !important;
  border-radius: 0.5rem;
  margin-top: 2rem;
  opacity: ${props => props.disabled && '0.4'};
  cursor: ${props => props.disabled && 'not-allowed'};
  
  :hover {
    text-decoration: none;
    cursor: pointer;
  }
`;
