import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns || '1fr auto'};
  gap: ${props => props.gap};
  width: 100%;
`;

export const Img = styled.img`
  height: 2.4rem;
  right: 2.5rem;
  max-width: unset;
`;

export const Label = styled.div``;
