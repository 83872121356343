import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  width: 100%;
  margin-bottom: -15px;
  height: max-content;

  a {
    color: ${props => props.theme.coachCard.color};
  }

  @media (max-width: 320px) {
    height: 230px;
  }
`;

export const Content = styled.div`
  padding: 20px;
  margin: 20px 10px;
  width: 100%;
  border-radius: 11px;
  box-shadow: 0 2px 7px 0 ${props => props.theme.coachCard.boxShadow};
  border: solid 0.5px ${props => props.theme.coachCard.borderColor};
  background-color: ${props => props.theme.coachCard.background};

  * {
    font-weight: 600;
  }
`;

export const FullName = styled.div`
  display: block;
  clear: both;
  color: ${props => props.theme.coachCard.color};
  margin: 0 10px 7px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.11px;
  @media (max-width: 1199px) {
    width: 200px;
  }
  @media (max-width: 1128px) {
    width: 194px;
  }
  @media (max-width: 1024px) {
    width: 158px;
  }
  @media (max-width: 991px) {
    width: 175px;
  }
  @media (max-width: 425px) {
    width: 175px;
  }
  @media (max-width: 360px) {
    width: 160px;
  }
  @media (max-width: 320px) {
    width: 150px;
  }
`;

export const Description = styled.div`
  display: block;
  clear: both;
  color: ${props => props.theme.coachCard.color};
  margin: 0 0 7px;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  @media (max-width: 1199px) {
    width: 180px;
  }
  @media (max-width: 1024px) {
    width: 145px;
  }
  @media (max-width: 991px) {
    width: 160px;
  }
  @media (max-width: 425px) {
    width: 165px;
  }
  @media (max-width: 360px) {
    width: 150px;
  }
  @media (max-width: 320px) {
    width: 138px;
  }
`;

export const Icon = styled.div`
  font-size: 13px;
  margin: 0 10px 0 10px;
  width: 10px;
  color: ${props => props.theme.coachCard.color};
`;

export const ButtonProfile = styled.a`
  display: block;
  white-space: normal;
  height: inherit !important;
  border-radius: 4px !important;
  background-color: ${props => props.theme.coachCard.button.background};
  padding: 15px;
  text-transform: none;
  border-radius: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  color: ${props => props.theme.coachCard.button.color} !important;
  text-align: center;
  margin-top: 12px;
  letter-spacing: -0.1px;
  font-weight: 600;
  font-size: 15px;

  :hover {
    cursor: pointer;
    color: ${props => props.theme.coachCard.button.colorHover} !important;
    background-color: ${props => props.theme.coachCard.button.backgroundHover};
  }
`;
