import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  font-size: 1.4rem;

  max-width: 117.0rem;
  width: 80vw;
  margin: 0 auto;

  ${media.lessThan('medium')`
    max-width: unset;
    width: unset;
  `}
`;
