import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './section-primary.scss';

const CardPrimary = ({ children }) => <div className="section-primary">{children}</div>;

CardPrimary.propTypes = { children: PropTypes.element };

CardPrimary.defaultProps = { children: <></> };

export default memo(CardPrimary);
