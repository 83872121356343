/* eslint-disable no-nested-ternary */
import React from 'react';
import PropType from 'prop-types';

import { Container, Content, FullName, Description, Icon, ButtonProfile } from './styled';
import Avatar from '../../avatar';

const CardCoach = ({ name, location, industry, language, img, alt, handleShow }) => {
  return (
    <>
      <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
        <Container>
          <Content>
            <div className="pure-g" style={{ flexFlow: 'nowrap' }}>
              <Avatar img={img} alt={alt} />
              <div>
                <div className="pure-g">
                  <FullName>{name}</FullName>
                </div>
                {location && (
                  <div className="pure-g">
                    <Icon>
                      <img src="/images/location-small.svg" alt="location" />
                    </Icon>
                    <Description>{location}</Description>
                  </div>
                )}
                {industry && (
                  <div className="pure-g">
                    <Icon>
                      <img src="/images/industry-small.svg" alt="industry" />
                    </Icon>
                    <Description>{industry}</Description>
                  </div>
                )}
                {language && (
                  <div className="pure-g">
                    <Icon>
                      <img src="/images/language-small.svg" alt="language" />
                    </Icon>
                    <Description>{language}</Description>
                  </div>
                )}
              </div>
            </div>
            <ButtonProfile onClick={handleShow}>View Profile</ButtonProfile>
          </Content>
        </Container>
      </div>
    </>
  );
};

CardCoach.propTypes = {
  name: PropType.string,
  location: PropType.string,
  industry: PropType.string,
  language: PropType.string,
  img: PropType.string,
  alt: PropType.string,
  handleShow: PropType.func,
};

CardCoach.defaultProps = {
  name: '',
  location: '',
  industry: '',
  language: '',
  img: '',
  alt: '',
  handleShow: () => {},
};

export default CardCoach;
