import React from 'react';
import PropType from 'prop-types';
import '../style/field-text.css';

const FieldText = props => {
  const { id, label, placeholder, className, value, onChange, maxLength, img } = props;

  return (
    <div className={`field-text ${className}`}>
      <div className="field-text__label-container">
        <label className="field-text__label" htmlFor={id}>
          {label}
        </label>
        <div className="field-text__field-text">
          <img className="field-text__img" src={img} alt="" />
          <input
            id={id}
            type="text"
            placeholder={placeholder}
            defaultValue={value}
            maxLength={maxLength}
            onChange={onChange}
            className={`field-text__input field-text__input-wbecs ${img &&
              'field-text__input-with-img'} pure-u-24-24`}
          />
        </div>
      </div>
    </div>
  );
};

FieldText.defaultProps = {
  id: '',
  label: '',
  placeholder: 'Select ...',
  className: '',
  value: '',
  onChange: '',
  maxLength: 250,
  img: '',
};

FieldText.propTypes = {
  id: PropType.string,
  label: PropType.string,
  placeholder: PropType.string,
  className: PropType.string,
  value: PropType.any,
  onChange: PropType.any,
  maxLength: PropType.number,
  img: PropType.string,
};

export default FieldText;
