import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { Title, SubTitle, Description } from '../../../../shared/components/header/styled';
import HelpModal from '../helpModal';
import './header.scss';

const Header = ({ theme }) => {
  const [helpShow, setHelpShow] = useState(false);

  const handleCloseHelp = event => {
    event.preventDefault();
    setHelpShow(false);
  };

  const MenuExtraLinks = () => {
    if (theme.header.extraLinks === undefined) {
      return null;
    }
    return (
      theme.header.extraLinks.map((data)=>{
        return (
          <a
              href={data.link}
              target={data.target}
              className="coach-header-button coach-header-transparent coach-header-right coach-header-top pricing-button"
            >
            {data.text}
          </a>
        )
      })
    )
  };

  const HeaderDescription = () => {
    if (theme.header.description === undefined) {
      return null;
    }
    let sentences = theme.header.description.split("\n");
    return (
      sentences.map((data)=>{
        return (
          <p>{data}</p>
        )
      })
    )
  };

  const { header } = theme;

  return (
    <>
      <header className="header-default">
        <div className="header-default__logo">
          <a href="/">
            <img
              width="120"
              src={`/assets/${theme.directory}/${theme.logo}`}
              alt="Soar"
              className="brand-image"
            />
          </a>
          <button
            type="button"
            className="coach-header-button coach-header-transparent coach-header-right coach-header-top"
            onClick={() => setHelpShow(true)}
          >
            Help
          </button>
          <MenuExtraLinks />
          <div className="header-default__rectangle" />
        </div>
        <div className="pure-u-md-1-2">
          <Title className="header-default__title">{header.title}</Title>
          <SubTitle className="header-default__subtitle">{header.subTitle}</SubTitle>
          <Description className="header-default__content">
            <HeaderDescription />
          </Description>
        </div>
        <div className="pure-u-md-1-2 header-default__image_section">
          <div className="header-default__image_section__wrapper">
            <img
              src={`/assets/${theme.directory}/${header.image}`}
              className="header-default__image"
              alt="meeting"
            />
            {header.imageSourceReference && header.imageSubtitleReference && (
              <a
                href={header.imageSourceReference}
                className="header-default__reference"
                target="__blank"
              >
                {header.imageSubtitleReference}
              </a>
            )}
          </div>
        </div>
      </header>

      <HelpModal show={helpShow} handleClose={e => handleCloseHelp(e)} />
    </>
  );
};

Header.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(Header);
