import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import ProfileModal from './components/modal/default/ProfileModal';
import ProfileModalIcf from './components/modal/icf/ProfileModalIcf';

const ProfileModalContainer = props => {
  const { theme } = props;
  console.log(theme);
  return (
    <>
      {!theme || (theme.theme !== 'icf' && <ProfileModal {...props} />)}
      {theme.theme === 'icf' && <ProfileModalIcf {...props} />}
    </>
  );
};

ProfileModalIcf.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(ProfileModalContainer);
