import styled from 'styled-components';

export const Container = styled.div`
  height: 158px;
  border-radius: 12px;
  flex: 1;
  font-family: ProximoNova !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${props => {
    const serialized = props.theme.steps.map(item => {
      if (item.type === props.step) return item.color;
      return '';
    });
    return serialized;
  }};
  border: solid 0.5px
    ${props => {
      const serialized = props.theme.steps.map(item => {
        if (item.type === props.step) return item.border;
        return '';
      });
      return serialized;
    }};
  background-color: ${props => {
    const serialized = props.theme.steps.map(item => {
      if (item.type === props.step) return item.background;
      return '';
    });
    return serialized;
  }};

  ${({ margin }) =>
    margin &&
    `
    margin-right: 30px;

    @media (max-width: 768px) {
      margin-right: 10px;
    }
  `}

  @media (max-width: 425px) {
    margin: 5px 2px 5px 2px;
  }
`;

export const Content = styled.div`
  margin-top: 25px;
  padding: 0px 24px;

  p {
    margin-top: 5px;
  }

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
  @media (max-width: 425px) {
    margin-bottom: 15px;
  }
`;

export const Title = styled.strong`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.58px;

  @media (max-width: 768px) {
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 0.46px;
  }
`;

export const SubtTitle = styled.strong`
  font-size: 19px;
  font-weight: 600;
  letter-spacing: -0.12px;

  @media (max-width: 768px) {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.11px;
  }
`;

export const Text = styled.span`
  font-size: 17px;
  font-weight: normal;
  letter-spacing: -0.11px;

  @media (max-width: 768px) {
    font-size: 15px;
    font-weight: normal;
    letter-spacing: -0.1px;
  }
`;
