import styled from 'styled-components';
import ReactSwitch from 'react-switch';
import BootstrapTabs from 'react-bootstrap/Tabs';
import BootstrapTab from 'react-bootstrap/Tab';

// Start - Components mobile
export const Tabs = styled(BootstrapTabs)`
  justify-content: center;
`;

export const Tab = styled(BootstrapTab)`
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`;
// End - Components mobile

const mobile = 'screen and (max-width: 1000px)';

export const Switch = styled(ReactSwitch)`
  margin: 0 1.5rem 0.2rem 1.5rem;
`;

export const SwitchLabel = styled.div`
  font-size: 1.5rem;
  color: #000000;
  font-weight: ${props => props.fontWeight};
`;

export const PricingHeaderGrid = styled.div`
  padding-top: 6rem;
  display: grid;
  gap: 0;
  grid-template-columns: 47fr 64fr;

  @media ${mobile} {
    padding-top: 2rem;
    grid-template-columns: 100%;
  }
`;

export const PricingHeaderPlansGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  @media ${mobile} {
  }
`;
