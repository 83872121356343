import React, { memo } from 'react';
import { withTheme } from 'styled-components';
import PropType from 'prop-types';
import Field from './default';
import FieldWbecs from './wbecs';

const FieldText = props => {
  const { id, label, placeholder, className, value, onChange, img, maxLength, theme } = props;


  return (
    <>
      {!theme ||
        (theme.theme !== 'wbecs' && (
          <Field
            id={id}
            label={label}
            img={img}
            className={className}
            placeholder={placeholder}
            defaultValue={value}
            maxLength={maxLength}
            onChange={onChange}
          />
        ))}
      {theme.theme === 'wbecs' && (
        <FieldWbecs
          id={id}
          label={label}
          img={img}
          className={className}
          placeholder={placeholder}
          defaultValue={value}
          maxLength={maxLength}
          onChange={onChange}
        />
      )}
    </>
  );
};
FieldText.defaultProps = {
  id: '',
  label: '',
  placeholder: 'Select ...',
  className: '',
  value: '',
  onChange: '',
  maxLength: 250,
  img: '',
};

FieldText.propTypes = {
  id: PropType.string,
  label: PropType.string,
  placeholder: PropType.string,
  className: PropType.string,
  value: PropType.any,
  onChange: PropType.any,
  maxLength: PropType.number,
  img: PropType.string,
};

export default memo(withTheme(FieldText));
