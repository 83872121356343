import styled from 'styled-components';
import media from 'styled-media-query';

export const Cell = styled.div`
  grid-column: ${props => props.gridColumn};
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  background-color: ${props => props.backgroundColor || '#fff'};
  padding: ${props => props.padding || '2rem 2.2rem'};
  border-radius: ${props => props.borderRadius};
  border: ${props => props.border || 'solid 1px #caccd8'};
  border-top: ${props => props.borderTop};
  border-bottom: ${props => props.borderBottom};
  border-left: ${props => props.borderLeft};
  border-right: ${props => props.borderRight};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};

  ${media.lessThan('medium')`
    padding: 2rem 0.5rem;
    border-radius: unset;
  `}
`;
