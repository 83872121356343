import AudienceICF from '../audienceIcf';
import FormICF from '../formIcf';
import FormICFRequestCode from '../formIcfRequestCode';
import SearchPage from '../searchCoaches';
import PlansPage from '../price-plans';
import CustomPage from '../custom-page';

export default {
  Audience: AudienceICF,
  Search: SearchPage,
  Plans: PlansPage,
  CustomPage: CustomPage,
  Form: FormICF,
  ICFRequestCode: FormICFRequestCode,
};
