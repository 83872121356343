import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '../../Grid';
import Header from '../../Grid/Cell/Header';
import Body from '..';

const GridSupport = () => {
  const support = useSelector(state => state.pricePlans.plansAccess.support);
  return (
    <Grid>
      <Header cellPosition="middleMiddle" gridColumns={2}>Support</Header>
      <Body items={support} />
    </Grid>
  );
};

export default GridSupport;
