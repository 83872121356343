import React from 'react';
import { Bubbles } from './styles';

const LoaderBubble = () => (
  <Bubbles>
    <div />
    <div />
    <div />
  </Bubbles>
);

export default LoaderBubble;
