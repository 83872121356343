import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  width: 35vw;
  padding: 6.5rem 7.5rem 7.5rem;
  border-radius: .5rem;
  box-shadow: 0 .2rem 1.0rem 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  display: flex;
  align-items: center;
  flex-direction: column;

  ${media.lessThan('large')`
    width: 55vw;
  `}
  ${media.lessThan('medium')`
    width: 70vw;
    padding: 5rem 3rem;
  `}
  ${media.lessThan('small')`
    width: 90vw;
    padding: 4rem 2rem;
  `}
`;

export const H1 = styled.div`
  font-size: 2.2rem;
  font-weight: bold;
`;

export const H2 = styled.div`
  font-size: 1.8rem;
  margin-top: 1.1rem;
`;

export const Img = styled.img`
  margin-top: 4.8rem;
  width: 76%;
`;

export const Description = styled.div`
  font-size: 1.4rem;
  margin-top: 3.9rem;
`;

export const Button = styled.a`
  margin-top: 3.9rem;
  background-color: #7909ea;
  color: #ffffff;
  padding: 1.5rem 3.4rem;
  border-radius: 2.4rem;
  line-height: 1;
  align-self: center;
  font-size: 1.4rem;
  font-weight: bold;
  border: none;

  :hover {
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
  }

  :disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;
