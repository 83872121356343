import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '../../Grid/index';
import Header from '../../Grid/Cell/Header';
import Body from '..';

const GridFeatures = () => {
  const features = useSelector(state => state.pricePlans.plansAccess.features);
  return (
    <Grid>
      <Header cellPosition="leftTopSecound" gridColumns={2}>Features</Header>
      <Body items={features} />
    </Grid>
  );
};

export default GridFeatures;
