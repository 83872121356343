/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '../Grid';
import Cell from '../Grid/Cell';
import DetailPlan from './DetailPlan';
import { Switch, SwitchLabel, Tabs, Tab } from './styles';
import { MEDIUM } from '../../../shared/constants/width-constants';
import withWindowResize from '../../../shared/hocs/withWindowResize';
import { setPeriod, setPlan } from '../../actions/price-plans-actions';

const GridHeader = ({ width }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const period = useSelector(state => state.pricePlans.period);
  const plan = useSelector(state => state.pricePlans.plan);
  const plansHeader = useSelector(state => state.pricePlans.plansHeader[period]);

  const handleSetPeriod = () => {
    dispatch(setPeriod(period === 'monthly' ? 'annually' : 'monthly'));
  };

  const handleSetPlan = () => {
    dispatch(setPlan(plan === 'basic' ? 'premium' : 'basic'));
  };

  const handleSetChecked = () => {
    setChecked(!checked);
    handleSetPeriod();
  };

  const BasicTitle = () => (
    <div style={{ padding: '1rem 2.6rem', fontSize: '1.7rem' }} onClick={handleSetPlan}>
      Basic
    </div>
  );
  const PremiumTitle = () => (
    <div style={{ padding: '1rem 2.6rem', fontSize: '1.7rem' }} onClick={handleSetPlan}>
      Premium
    </div>
  );
  const BasicContent = () => (
    <Cell border="none" justifyContent="center">
      <DetailPlan {...plansHeader[0]} />
    </Cell>
  );
  const PremiumContent = () => (
    <Cell border="none" justifyContent="center">
      <DetailPlan {...plansHeader[1]} />
    </Cell>
  );
  if (width.lessThan(MEDIUM)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Cell
          backgroundColor="transparent"
          padding="4.1rem 2.2rem"
          border="none"
          justifyContent="center"
          alignItems="flex-end"
        >
          <SwitchLabel fontWeight={period === 'monthly' ? '500' : ''}>Billed Monthly</SwitchLabel>
          <Switch
            onChange={handleSetChecked}
            checked={checked}
            onColor="#263692"
            offColor="#263692"
            height={18}
            width={33}
            uncheckedIcon={false}
            checkedIcon={false}
          />
          <SwitchLabel fontWeight={period === 'annually' ? '500' : ''}>Billed Annually</SwitchLabel>
        </Cell>
        <Tabs defaultActiveKey="basic" transition={false} id="noanim-tab-example">
          <Tab eventKey="basic" title={<PremiumTitle />}><BasicContent /></Tab>
          {/* <Tab eventKey="premium" title={<PremiumTitle />}><PremiumContent /></Tab> */}
        </Tabs>
      </div>
    );
  }

  return (
    <Grid paddingTop="6rem">
      <Cell
        backgroundColor="transparent"
        border="none"
        justifyContent="center"
        alignItems="flex-end"
      >
        <SwitchLabel fontWeight={period === 'monthly' ? '500' : ''}>Billed Monthly</SwitchLabel>
        <Switch
          onChange={handleSetChecked}
          checked={checked}
          onColor="#263692"
          offColor="#263692"
          height={18}
          width={33}
          uncheckedIcon={false}
          checkedIcon={false}
        />
        <SwitchLabel fontWeight={period === 'annually' ? '500' : ''}>Billed Annually</SwitchLabel>
      </Cell>

      {plansHeader.map((item, index, array) => {
        const isFirst = index === 0;
        const isLast = index === array.length - 1;
//        const cellPosition = isFirst ? 'leftTop' : isLast ? 'rightTop' : 'middleTop';
        const cellPosition = 'rightTop';
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Cell cellPosition={cellPosition} key={`${item.name}-${index}-cell`}>
            <DetailPlan {...item} />
          </Cell>
        );
      })}
    </Grid>
  );
};

GridHeader.propTypes = {
  width: PropTypes.any.isRequired,
};

export default withWindowResize(GridHeader);
