import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './card-primary.scss';

const CardPrimary = ({ imagePath, imageLink, body, footer, disabled }) => {
  const divClassName = classNames('card-primary', {
    'card-primary__disabled': disabled,
  });

  return (
    <div className={divClassName}>
      <div className="card-primary__header">
        <a href={imageLink}>
          <img className="card-primary__image" src={imagePath} alt="" />
        </a>
      </div>
      <div className="card-primary__wrap">
        <div className="card-primary__body">{body}</div>
        <div className="card-primary__footer">{footer}</div>
      </div>
    </div>
  );
};

CardPrimary.propTypes = {
  imagePath: PropTypes.string,
  imageLink: PropTypes.string,
  body: PropTypes.element,
  footer: PropTypes.element,
  disabled: PropTypes.bool,
};

CardPrimary.defaultProps = {
  imagePath: '',
  imageLink: '',
  body: <></>,
  footer: <></>,
  disabled: false,
};

export default memo(CardPrimary);
