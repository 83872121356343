import React from 'react';
import PropType from 'prop-types';
import Select from 'react-select';
import '../style/selected.css';

const Selected = props => {
  const { id, options, label, placeholder, className, value, onChange, isLoading, img } = props;
  const customStyles = {
    input: provided => ({
      ...provided,
      fontFamily: 'Work Sans',
      fontWeight: '600',
      fontSize: '13px',
      marginLeft: '30px',
      color: '#000',
    }),
    option: provided => ({
      ...provided,
      fontFamily: 'Work Sans',
      fontWeight: '600',
      fontSize: '13px',
      letterSpacing: '-0.08px',
      zIndex: '10',
    }),
    menu: provided => ({
      ...provided,
      zIndex: '10',
    }),
    placeholder: provided => ({
      ...provided,
      fontFamily: 'Work Sans',
      fontWeight: '600',
      fontSize: '13px',
      marginLeft: '30px',
      color: '#000',
    }),
  };

  return (
    <div className={`selected ${className}`}>
      <div className="selected__label-container">
        <label className="selected__label" htmlFor={id}>
          {label}
        </label>
        <div className="selected__selected">
          <img className="selected__img" src={img} alt="" />
          <Select
            id={id}
            placeholder={placeholder}
            options={options}
            value={value}
            onChange={onChange}
            isLoading={isLoading}
            className="selected__input pure-u-24-24"
            maxMenuHeight={200}
            styles={customStyles}
          />
        </div>
      </div>
    </div>
  );
};

Selected.defaultProps = {
  id: '',
  options: '',
  label: '',
  placeholder: 'Select ...',
  className: '',
  value: '',
  onChange: '',
  isLoading: '',
  img: '',
};

Selected.propTypes = {
  id: PropType.string,
  options: PropType.any,
  label: PropType.string,
  placeholder: PropType.string,
  className: PropType.string,
  value: PropType.any,
  onChange: PropType.any,
  isLoading: PropType.any,
  img: PropType.string,
};

export default Selected;
