export function verifyPlayer(urlVideoTmp) {
  /* eslint-disable no-useless-escape */
  const rg = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/g;
  const match = rg.exec(urlVideoTmp);
  if (match && match[1] !== undefined) {
    return 'youtube';
  }
  if (match && match[2] !== undefined) {
    return 'youtube';
  }
  return 'vimeo';
}

export function getVideoIdFromYouTubeUrl(videoUrl) {
  let videoId = null;

  if (videoUrl) {
    const regex = /=([\w-]{11})|\/([\w-]{11})/g;
    const match = regex.exec(videoUrl);
    if (match && match[1] !== undefined) {
      // eslint-disable-next-line prefer-destructuring
      videoId = match[1];
    } else if (match && match[2] !== undefined) {
      // eslint-disable-next-line prefer-destructuring
      videoId = match[2];
    } else {
      videoId = undefined;
    }
  }

  return videoId;
}

export function verifyURL(urlVideoTmp) {
  if (verifyPlayer(urlVideoTmp) === 'youtube') {
    if (urlVideoTmp.indexOf('embed') === -1) {
      const introVideoId = getVideoIdFromYouTubeUrl(urlVideoTmp);
      return `https://www.youtube.com/embed/${introVideoId}?rel=0&showinfo=0`;
    }
  }
  return urlVideoTmp;
}
