import { Record } from 'immutable';
import { PlanAccessRecord } from './plan-access-record';
import { PlanHeaderRecord } from './plan-header-record';

export const PricePlansRecord = Record({
  plan: 'basic',
  period: 'annually',
  planObj: { id: null },
  plansHeader: {
    monthly: [PlanHeaderRecord(), PlanHeaderRecord()],
    annually: [PlanHeaderRecord(), PlanHeaderRecord()],
  },
  plansAccess: {
    features: [PlanAccessRecord()],
    support: [PlanAccessRecord()],
    integrations: [PlanAccessRecord()],
  },
  fieldEmail: '',
  fieldEmailIsNewUser: false,
  fieldEmailWasVerified: false,
  fieldEmailIsValid: false,

  fieldCardIsValid: false,
  
  isLoadingSubscription: false,
  errorMessageSubscription: '',
  successMessageSubscription: '',
});
