import styled from 'styled-components';
import media from 'styled-media-query';

export const Grid = styled.div`
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.isLastGrid && '9.1rem'};
  display: grid;
  justify-items: stretch;
  gap: 0;
  grid-template-columns: 3fr 2fr;

  ${media.lessThan('medium')`
    grid-template-columns: 3fr 2fr;
  `}
`;
