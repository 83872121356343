import { useState, useEffect } from 'react';

const useWindowResize = () => {
  const findSize = (currentySize = window.outerWidth) => ({
    size: currentySize,
    lessThan: value => currentySize <= value,
    greaterThan: value => currentySize >= value,
    between: (valueA, ValueB) => currentySize >= valueA && currentySize <= ValueB,
  });

  const [size, setSize] = useState(findSize());

  useEffect(() => {
    const resize = () => {
      setSize(findSize());
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return size;
};

export default useWindowResize;
