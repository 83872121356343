import styled from 'styled-components';

export const Header = styled.div`
  position: relative;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  width: 100%;
  height: 350px;
  background-image: url(${props => props.headerImage});
  background-repeat: no-repeat;
  background-position: center center;
`;

export const Logo = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  width: 180px;
  height: 50px;
  background-image: url(${props => props.headerLogo});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;

export const Title = styled.span`
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;

  font-size: 30px;
  font-weight: 400;
  margin-left: 30px;
  color: #fff;
`;
