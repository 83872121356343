export const YEARS_EXPERIENCE_OPTIONS = [
  { value: '', label: 'Select ...' },
  { value: 1, label: '1 or more' },
  { value: 2, label: '2 or more' },
  { value: 3, label: '3 or more' },
  { value: 5, label: '5 or more' },
  { value: 10, label: '10 or more' },
];

export const ICF_CERTIFICATION_LEVEL = [
  { value: 'acc', label: 'Associate Certified Coach' },
  { value: 'mcc', label: 'Master Certified Coach' },
  { value: 'pcc', label: 'Professional Certified Coach' },
];
