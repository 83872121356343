import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-end;
  position: relative;
  width: 350px;
  height: 420px;
  margin: 0 1.5rem 5rem 1.6rem;
  padding: 0;
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: 330px;
  }
`;

export const CoachImageBlock = styled.div`
  display: block;
  position: absolute;
  width: 350px;
  height: 420px;
  background-image: url(${props => props.coachImage});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  border-radius: 0.5rem 0.5rem 0 0;
  @media (max-width: 768px) {
    width: 330px;
  }
`;

export const CoachImageView = styled.div`
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 260px;
  background-image: url(${props => props.coachImage});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  @media (max-width: 768px) {
    width: 320px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-end;
  position: absolute;
  bottom: 0;
  padding: 0 0 0 15px;
  background-color: rgba(248, 249, 255, 0.9);
`;

export const Header = styled.div`
  flex: 1;
  font-size: 25px;
  color: #d97706;
  margin-top: 5px;

  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.76px;
`;

export const InfoCoach = styled.div`
  display: flex;
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Description = styled.div`
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: ${props => `${props.marginLeft}px` || `0px`};
`;

export const Icon = styled.div`
  font-size: 13px;
  margin: 0 10px 0 10px;
  width: 10px;
  color: ${props => props.theme.coachCard.color};
  margin-top: ${props => `${props.marginTop}px` || `0px`};
  margin-bottom: ${props => `${props.marginBottom}px` || `0px`};
  margin-right: ${props => `${props.marginRight}px` || `0px`};
  margin-left: ${props => `${props.marginLeft}px` || `0px`};
  img{
    width: 18px;
    heigth: 18px;
  }
`;

export const Line = styled.div`
  border-left: 0.1rem solid #adabab;
  box-sizing: border-box;
  height: 0.1rem;
  width: 100%;
  background-color: #adabab;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  width: 170px;
  height: 34px;
  border-radius: 100px;
  background-color: ${props => props.theme.coachCard.button.background};

  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  color: ${props => props.theme.coachCard.button.color} !important;
  margin-bottom: 10px;
  > i {
    font-size: 20px;
    margin-left: 20px;
  }

  :hover {
    cursor: pointer;
    color: ${props => props.theme.coachCard.button.colorHover} !important;
    background-color: ${props => props.theme.coachCard.button.backgroundHover};
  }
`;