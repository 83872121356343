import React from 'react';

import CoachCard from './default/index';
import CoachCardWbecs from './wbecs/index';

const CoachCardContainer = props => {
  const { item, img, alt, name, location, industry, language, theme, handleShow } = props;
  return (
    <>
      {!theme ||
        (theme.theme !== 'wbecs' && (
          <CoachCard
            item={item}
            img={img}
            alt={alt}
            name={name}
            location={location}
            industry={industry}
            language={language}
            handleShow={() => handleShow(item)}
          />
        ))}
      {theme.theme === 'wbecs' && (
        <CoachCardWbecs
          item={item}
          img={img}
          alt={alt}
          name={name}
          location={location}
          industry={industry}
          language={language}
          handleShow={() => handleShow(item)}
        />
      )}
    </>
  );
};

export default CoachCardContainer;
