import React from 'react';
import { PropTypes } from 'prop-types';
import { MEDIUM } from '../../../../../shared/constants/width-constants';
import withWindowResize from '../../../../../shared/hocs/withWindowResize';
import { Wrapper, Img, Label } from './styles';

const BillingInvoicing = ({ width }) => {
  const showObs = width.size > MEDIUM;
  const style = {
    gap: showObs && '1rem',
    gridTemplateColumns: showObs ? '1fr auto' : '1fr',
  };

  return (
    <Wrapper {...style}>
      <Label>Billing & Invoicing your clients</Label>
      {showObs && <Img src="/images/pricePlans/powered_by_stripe.png" alt="powered by Stripe" />}
    </Wrapper>
  );
};

BillingInvoicing.propTypes = {
  width: PropTypes.any.isRequired,
};

export default withWindowResize(BillingInvoicing);
