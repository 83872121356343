import React from 'react';
import { withTheme } from 'styled-components';

import Header from './default/header';
import HeaderWbecs from './wbecs';

const HeaderContainer = props => {
  const { theme } = props;
  return (
    <>
      {!theme || (theme.theme !== 'wbecs' && <Header/>)}
      {theme.theme === 'wbecs' && <HeaderWbecs/>}
    </>
  );
};

export default withTheme(HeaderContainer);
