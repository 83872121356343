import React, { useState, memo } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { Header, Title, Logo } from './styled';
import HelpModal from '../helpModal';
import './styled.scss';

const HeaderWbecs = ({ theme }) => {
  const [helpShow, setHelpShow] = useState(false);
  const [className, setClassName] = useState('');

  const handleCloseHelp = event => {
    event.preventDefault();
    setHelpShow(false);
  };

  const setMobileMenu = () => {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
    setClassName('responsive');
  };

  const { header } = theme;
  return (
    <>
      <header className="header-default">
        <div className="header-default__warning">
          Read about our commitment to racial equality in our latest diversity report | COVID-19
          Response
        </div>
        <Header headerImage={`/assets/${theme.directory}/${header.image}`}>
          <Logo headerLogo={`/assets/${theme.directory}/${theme.logo}`} />

          <div className={`topnav ${className}`} id="myTopnav">
            <a href="https://www.wbecs.com/">Home</a>
            <a href="https://www.wbecs.com/about/">About Us</a>
            <a href="https://wbecs.com/wbecs20/passes/">Full-Summit 2020</a>
            <a href="https://ethicalcoach.org/">EthicalCoach</a>
            <a href="https://www.wbecs.com/programs/">Our Program</a>
            <a href="https://www.wbecs.com/wbecs2018/become-partner/">Become a Partner</a>
            <a href="https://www.wbecs.com/blog/">Blog</a>
            <a href="https://www.wbecs.com/contact/">Contact Us</a>
            <a href="#" className="icon" onClick={() => setMobileMenu()}>
              <i className="fa fa-bars" />
            </a>
          </div>

          <Title>{header.title}</Title>
        </Header>
      </header>

      <HelpModal show={helpShow} handleClose={e => handleCloseHelp(e)} />
    </>
  );
};

HeaderWbecs.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default memo(withTheme(HeaderWbecs));
