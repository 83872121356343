import styled from 'styled-components';

export const Title = styled.p`
  color: ${props => props.theme.header.color.title};
`;

export const SubTitle = styled.div`
  color: ${props => props.theme.header.color.subTitle};
`;

export const Description = styled.div`
  color: ${props => props.theme.header.color.description};
`;
