import React from 'react';

import { Container, Title, SubtTitle, Text, Content } from './styled';
import './index.css';

const Step = props => {
  const { title, subTitle, text, margin, step } = props;

  return (
    <Container step={step} margin={margin}>
      <Content>
        <>
          <Title>{title}</Title>
          <p>
            <SubtTitle>{subTitle}</SubtTitle>
          </p>
          <Text>{text}</Text>
        </>
      </Content>
    </Container>
  );
};

export default Step;
