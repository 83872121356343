import 'core-js/stable';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@fortawesome/fontawesome-pro/css/all.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import 'purecss/build/grids-min.css';
import 'purecss/build/grids-responsive-min.css';
import { ThemeProvider } from 'styled-components';

import * as serviceWorker from './serviceWorker';
import defaultTheme from './defaultSite/themeDefault.json';
import defaultThemeSite from './defaultSite/themeSite.json';
import GlobalStyled from './defaultSite/styled';
import ComponentRegistry from './constants/pages';
import NotFound from './notfound';
import store from './redux/store';
import './App.css';

const { themeSite: siteContent } = serviceWorker.checkDirectory();
const themeSite = { ...defaultThemeSite, ...siteContent };
const history = createBrowserHistory();
const jsonRoutes = themeSite.routes;

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={{ ...defaultTheme, ...themeSite }}>
      <GlobalStyled />
      <Router history={history}>
        <Switch>
          {jsonRoutes.map(({ path, component, exact }) => {
            return (
              <Route
                key={path}
                path={path}
                exact={exact}
                component={ComponentRegistry[component]}
              />
            );
          })}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
