import { createAction } from 'redux-actions';
import { httpClient } from '../../shared/helpers/request-helper';

const baseURL = process.env.REACT_APP_SOAR_API_HOST;

export const setFieldEmail = createAction('PRICE_PLANS/SET_FIELD_EMAIL', value => value);
export const setFieldEmailIsNewUser = createAction('PRICE_PLANS/SET_FIELD_EMAIL_IS_NEW_USER', value => value);
export const setFieldEmailWasVerified = createAction('PRICE_PLANS/SET_FIELD_EMAIL_WAS_VERIFIED', value => value);
export const setFieldEmailIsValid = createAction('PRICE_PLANS/SET_FIELD_EMAIL_IS_VALID', value => value);

export const setFieldCardIsValid = createAction('PRICE_PLANS/SET_FIELD_CARD_IS_VALID', value => value);

export const setIsLoadingSubscription = createAction('SUBSCRIPTION/SET_IS_LOADING', value => value);
export const setErrorMessageSubscription = createAction('SUBSCRIPTION/SET_ERROR_MESSAGE', value => value);
export const setSuccessMessageSubscription = createAction('SUBSCRIPTION/SET_SUCCESS_MESSAGE', value => value);

export const getUserByEmail = email =>
  new Promise((res, rej) => {
    httpClient
      .get(`${baseURL}/user/byEmail?email=${email}`)
      .then(({ data }) => res(data[0]))
      .catch(({ response: { status } }) => String(status)[0] === 5 ? rej() : res({ id: 0 }));
  });

export const getPlansUser = userId =>
  new Promise((res, rej) => {
    httpClient
      .get(`${baseURL}/Subscription/${userId}`)
      .then(({ data }) => res(data))
      .catch(() => rej());
  });

export const postUser = email =>
  new Promise((res, rej) => {
    const data = {
      emails: [{ emailAddress: email, type: 'Main' }],
      signupCode: 10,
      gsnStatus: 110,
      isCoach: true,
    };
    httpClient
      .post(`${baseURL}/user`, data)
      .then(({ data }) => res(data))
      .catch(() => rej());
  });

export const alterPlan = ({ cardToken, userId, coachPlans, newPlanId }) =>
  new Promise(async (resove, reject) => {
    try {
      const stripeId = coachPlans.length ? coachPlans[0].stripeSubscriptionId : null;
      const cancelledOn = coachPlans.length ? coachPlans[0].cancelledOn : null;
      let res = '';

      if (stripeId && cancelledOn === null) {
        res = await httpClient.patch(
          `${baseURL}/Subscription/updatePlan/${userId}/${stripeId}/${newPlanId}`
        );
      } else {
        if (stripeId) {
          await httpClient.patch(`${baseURL}/Subscription/cancel/${stripeId}/now`);
        }
        res = await httpClient.post(`${baseURL}/Subscription/${userId}/${newPlanId}/${cardToken}`);
      }

      if (res.status !== 200) throw new Error();

      resove();
    } catch {
      reject();
    }
  });
