import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const Avatar = ({ img, alt }) => (
  <div className="coach-image-box">
    <div className="avatar-div">
      <img src={img} alt={alt} className="avatar-img" />
    </div>
  </div>
);

Avatar.propTypes = {
  img: PropTypes.string,
  alt: PropTypes.string,
};

Avatar.defaultProps = {
  img: '',
  alt: '',
};

export default Avatar;
