import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import Header from '../shared/components/header';
// import BoxAudience from '../layout/components/boxAudience';
import Section from '../shared/components/section-primary';
import Card from '../shared/components/card-primary';
import Button from '../shared/components/button-primary';
import './index.scss';

const headerParagraph = (
  <>
    <p>During these unprecedented times, the world needs coaches and coaching more than ever.</p>
    <p>
      Coaches credentialed by the International Coaching Federation have met stringent education and
      experience requirements and have demonstrated a thorough understanding of the coaching
      competencies that set the standard in the profession. They also adhere to strict ethical
      guidelines as part of ICF’s mission to protect and serve coaching consumers.
    </p>
    <p>
      ICF Coaches for Good is a groundbreaking initiative designed to connect ICF-credentialed
      coaches from around the world with individuals and organizations who stand to benefit the most
      from pro bono coaching during the COVID-19 crisis.
    </p>
  </>
);

const textCard1 = (
  <div className="audience-icf__card-body">
    <strong>Are you an ICF-credentialed coach?</strong>
    <br />
    <span>
      Learn how you can make an impact through pro bono coaching during the COVID-19 crisis.
    </span>
  </div>
);
const buttonCard1 = (
  <Button link="https://coachfederation.org/coach-for-good" fluid>
    Learn More
  </Button>
);

const textCard2 = (
  <div className="audience-icf__card-body">
    <strong>
      Are you from a non-profit, membership association, NGO or government agency that wants to
      connect constituents with pro bono coaching?
    </strong>
    <br />
    <span>Find out how to apply for coaching through this unique initiative.</span>
  </div>
);
const buttonCard2 = (
  <Button link="/icf-form" fluid>
    Learn More
  </Button>
);

const textCard3 = (
  <div className="audience-icf__card-body">
    <strong>
      Discover how partnering with an ICF-credentialed coach can help you navigate personal and
      professional challenges and begin moving forward.
    </strong>
  </div>
);
const buttonCard3 = (
  <Button link="/search" fluid>
    Learn More
  </Button>
);

const AudienceICF = ({ theme }) => {
  return (
    <div className="audience-icf">
      <Header
        imagePath={`/assets/${theme.directory}/${theme.header.image}`}
        title={theme.header.title}
        logoPath={`/assets/${theme.directory}/${theme.logo}`}
        subtitle={theme.header.subTitle}
        paragraph={headerParagraph}
      />
      <Section>
        <div className="grid">
          <div className="grid-item-1">
            <Card
              imagePath="/images/consultant@2x.png"
              imageLink="https://coachfederation.org/coach-for-good"
              body={textCard1}
              footer={buttonCard1}
            />
          </div>
          <div className="grid-item-2">
            <Card
              imagePath="/images/org@2x.png"
              imageLink="/icf-form"
              body={textCard2}
              footer={buttonCard2}
            />
          </div>
          <div className="grid-item-3">
            <Card
              imagePath="/images/client@2x.png"
              imageLink="/search"
              body={textCard3}
              footer={buttonCard3}
            />
          </div>
        </div>
      </Section>
    </div>
  );
};

AudienceICF.propTypes = {
  theme: PropTypes.object,
};

AudienceICF.defaultProps = {
  theme: {},
};

export default withTheme(AudienceICF);
