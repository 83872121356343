import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import ReactModal from 'react-modal';
import useKeypress from 'react-use-keypress';

import Avatar from '../../../../components/avatar';
import { URL_ICF_CERT } from '../../../constants/profile-constants';
import { verifyURL } from '../../../../shared/helpers/converter-helper';
import { isEmail } from '../../../../shared/helpers/validation-helper';
import LoaderBubble from '../../../../shared/components/loader-bubble';
import { crypt } from '../../../../shared/helpers/crypt-helper';
import { httpClient } from '../../../../shared/helpers/request-helper';
import { ButtonChoose, InputEmailChoose, InputEmailChooseError } from './styles';
import './style.scss';

const loader = <div className="loader">Loading...</div>;
const appUrl = process.env.REACT_APP_URL || 'https://app.soar.com';

const ProfileModalIcf = props => {
  const {
    show,
    handleClose,
    profile,
    isLoadingProfile,
    splitFunction,
    industryAPI,
    languagesAPI,
    buttonChooseCoach,
  } = props;
  const clientUrl = process.env.REACT_APP_BACKEND_NODE;
  const [buttonChooseStatus, setButtonChooseStatus] = useState({
    isLoading: false,
    successMsg: '',
  });
  const [showModalChooseCoach, setShowModalChooseCoach] = useState(false);
  const [userEmail, setUserEmail] = useState({ value: '', errorMsg: '' });

  const closeMainModal = e => {
    setShowModalChooseCoach(false);
    handleClose(e);
  };

  useEffect(() => {
    setButtonChooseStatus({ isLoading: false, successMsg: '' });
  }, [profile.id]);

  const getBadgeUrl = () => {
    let url = '';
    if (profile.customFields.icfCertLevel) {
      url = URL_ICF_CERT[profile.customFields.icfCertLevel];
    }
    return url;
  };

  const buildLocation = () => {
    if (profile.addresses[0]) {
      const addresses = profile.addresses[0];
      return [addresses.locality, addresses.region, addresses.countryCode]
        .filter(Boolean)
        .join(', ');
    }
    return '';
  };

  const buildProfileImg = item => {
    let img = `https://i1.wp.com/cdn.auth0.com/avatars/${item.givenName
      .substring(0, 1)
      .toLowerCase()}.png`;

    if (item.customFields.ProfilePictUrl && item.customFields.ProfilePictUrl !== '') {
      img = item.customFields.ProfilePictUrl.replace('http://', 'https://');
    }
    return img;
  };
  const customStyles = {
    content: {
      zIndex: 2,
      border: 'none',
      background: 'none',
      padding: '0',
      top: '0',
      left: '0px',
      right: '0px',
      display: 'flex',
      justifyContent: 'center',
      bottom: 'auto',
      outline: 'none',
    },
  };

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'unset';
  }, [show]);

  const sendEmail = async () => {
    if (isEmail(userEmail.value)) {
      if (
        !buttonChooseStatus.isLoading &&
        !buttonChooseStatus.successMsg &&
        !buttonChooseStatus.errorMsg
      ) {
        setButtonChooseStatus({ ...buttonChooseStatus, isLoading: true });
        const hash = crypt(`${String(profile.id)}?${userEmail.value}`);
        await httpClient.post(`${clientUrl}api/choose-coach/${hash}`);
        setButtonChooseStatus({
          ...buttonChooseStatus,
          isLoading: false,
          successMsg: 'Coach chosen',
        });
        setShowModalChooseCoach(false);
      }
    } else {
      setUserEmail({ ...userEmail, errorMsg: 'Invalid email!' });
    }
  };

  const handleChangeEmailChoose = e => {
    if (!isEmail(e.target.value) && userEmail.errorMsg)
      setUserEmail({ ...userEmail, value: e.target.value });
    else setUserEmail({ errorMsg: '', value: e.target.value });
  };

  useKeypress('Enter', () => sendEmail());

  const ChooseCoachModal = () => {
    const loading = <LoaderBubble />;
    const actions = (
      <>
        <InputEmailChoose
          type="email"
          placeholder="Type your email here"
          value={userEmail.value}
          borderColor={userEmail.errorMsg && '#dc3545'}
          onChange={handleChangeEmailChoose}
        />
        <InputEmailChooseError>{userEmail.errorMsg}</InputEmailChooseError>
        <div className="choose-coach__email-actions">
          <ButtonChoose onClick={() => setShowModalChooseCoach(false)} width="unset">
            Close
          </ButtonChoose>
          <ButtonChoose onClick={sendEmail} background="#0166D7" color="#fff">
            Confirm
          </ButtonChoose>
        </div>
      </>
    );

    return (
      <ReactModal
        isOpen={showModalChooseCoach}
        contentLabel="Choose this Coach | Email"
        style={customStyles}
        onRequestClose={closeMainModal}
        shouldCloseOnOverlayClick
        className="pure-u-xl-3-5 pure-u-lg-4-5 pure-u-md-7-8 pure-u-sm-5-5"
      >
        <div className="profile-modal-container__choose-coach pure-u-xl-5-5 pure-u-lg-5-5pure-u-md-5-5 pure-u-sm-5-5">
          {buttonChooseStatus.isLoading ? loading : actions}
        </div>
      </ReactModal>
    );
  };

  const openChooseCoachModal = () => {
    if (!buttonChooseStatus.successMsg) setShowModalChooseCoach(!showModalChooseCoach);
  };

  return (
    <>
      <ReactModal
        isOpen={show}
        contentLabel="Minimal Modal Example"
        style={customStyles}
        onRequestClose={closeMainModal}
        shouldCloseOnOverlayClick
        className="pure-u-xl-3-5 pure-u-lg-4-5 pure-u-md-7-8 pure-u-sm-5-5"
      >
        <div className="profile-modal-container pure-u-xl-5-5 pure-u-lg-5-5pure-u-md-5-5 pure-u-sm-5-5">
          {isLoadingProfile ? (
            loader
          ) : (
            <>
              <div className="profile-modal-header">
                <div className="info pure-u-xl-3-5 pure-u-lg-3-5 pure-u-md-3-5 pure-u-sm-5-5 pure-u-5-5">
                  <div className="coach-picture">
                    <Avatar
                      img={buildProfileImg(profile)}
                      alt={`${profile.givenName} ${profile.familyName}`}
                    />
                  </div>
                  <ul className="couch-info">
                    <li>
                      <h3 className="modal-information coach-name">
                        {profile.givenName} {profile.familyName}
                      </h3>
                    </li>
                    {profile.customFields.icfIndustries && (
                      <li>
                        <h5 className="modal-information coach-industries">
                          {splitFunction(profile.customFields.icfIndustries, industryAPI)}
                        </h5>
                      </li>
                    )}
                    <li>
                      <div className="coach-location">
                        <i className="profile-icon icon-location-small">&#xe803;</i>
                        <span className="location-text">{buildLocation()}</span>
                      </div>
                    </li>
                    {/* {profile.customFields.icfLanguages && (
                      <li>
                        <i className="profile-icon icon-language">&#xe801;</i>
                        <span>
                          {splitFunction(profile.customFields.icfLanguages, languagesAPI)}
                        </span>
                      </li>
                    )} */}
                  </ul>
                </div>
                <div className="modal-profil-actons pure-u-xl-2-5 pure-u-lg-2-5 pure-u-md-2-5 pure-u-sm-5-5 pure-u-5-5">
                  {profile.customFields.icfDigitalBadgeUrl && (
                    <a
                      href={profile.customFields.icfDigitalBadgeUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="header-badge"
                    >
                      <img src={getBadgeUrl()} alt={profile.icfCertLevel} />
                    </a>
                  )}
                  {buttonChooseCoach && (
                    <ButtonChoose
                      onClick={openChooseCoachModal}
                      {...(buttonChooseStatus.successMsg && {
                        background: '#dcf4f7',
                        color: 'green',
                        disabled: true,
                      })}
                    >
                      {buttonChooseStatus.successMsg || 'Choose this Coach'}
                    </ButtonChoose>
                  )}
                  {profile.customFields.SchedulingIds && (
                    <a
                      className="pure-button blue-button-icf action-button"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.soar.com/${profile.customFields.VanityUrl}?isSchedule=true`}
                    >
                      <i className="profile-icon icon-calendar">&#xe804;</i>
                      &nbsp;Schedule Now
                    </a>
                  )}
                  {profile.customFields.VanityUrl && (
                    <a
                      href={`${appUrl}/${profile.customFields.VanityUrl}`}
                      className="button-link silver-gray-button action-button"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      View Profile
                    </a>
                  )}
                  {/* <button
                    type="button"
                    className="button-link silver-gray-button action-button close-button"
                    onClick={closeMainModal}
                  >
                    Close
                  </button> */}
                  <button type="button" className="modal-close" onClick={closeMainModal}>
                    x
                  </button>
                </div>
              </div>
              <div className="profile-modal-body">
                <div className="modal-body-left pure-u-lg-1-5 pure-u-md-2-6 pure-u-sm-1-5 sm-hide">
                  <ul className="modal-profile-menu">
                    {profile.customFields.IntroVideoUrl && (
                      <li>
                        <a href="#section_video">
                          <i className="profile-icon icon-video-small">&#xe807;</i>
                          &nbsp;Video
                        </a>
                      </li>
                    )}
                    {profile.customFields.AboutMe && (
                      <li>
                        <a href="#section-about">
                          <i className="profile-icon icon-about-small">&#xe806;</i>
                          &nbsp;About
                        </a>
                      </li>
                    )}
                    {profile.customFields.ShortBio && (
                      <li>
                        <a href="#section-bio">
                          <i className="profile-icon icon-bio-small">&#xe805;</i>
                          &nbsp;Bio
                        </a>
                      </li>
                    )}
                    {profile.customFields.icfDigitalBadgeUrl && (
                      <li className="icf_badge">
                        <a
                          href={profile.customFields.icfDigitalBadgeUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={getBadgeUrl()} alt={profile.icfCertLevel} width="92px" />
                        </a>
                      </li>
                    )}
                    <li className="modal_soar_logo">
                      <img src="/images/logo.png" alt="Soar" />
                    </li>
                  </ul>
                </div>
                <div className="modal-body-right pure-u-lg-4-5 pure-u-md-4-6 pure-u-sm-4-5">
                  {profile.customFields.IntroVideoUrl && (
                    <div className="section-video" id="section_video">
                      <iframe
                        src={verifyURL(profile.customFields.IntroVideoUrl)}
                        frameBorder="0"
                        allowFullScreen
                        title={`${profile.givenName}'s intro video`}
                        className="embed-responsive-item"
                      />
                    </div>
                  )}
                  {profile.customFields.AboutMe && (
                    <div className="section" id="section-about">
                      <div className="title">About</div>
                      <div className="section-content">{Parser(profile.customFields.AboutMe)}</div>
                    </div>
                  )}
                  {profile.customFields.ShortBio && (
                    <div className="section" id="section-bio">
                      <div className="title">Short Bio</div>
                      <div className="section-content">{Parser(profile.customFields.ShortBio)}</div>
                    </div>
                  )}
                  <div className="section-logo">
                    <span>POWERED BY</span>
                    <img src="/images/logo.png" alt="Soar" />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {ChooseCoachModal()}
      </ReactModal>
    </>
  );
};

ProfileModalIcf.propTypes = {
  profile: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoadingProfile: PropTypes.bool.isRequired,
  splitFunction: PropTypes.func.isRequired,
  industryAPI: PropTypes.array.isRequired,
  languagesAPI: PropTypes.array.isRequired,
  buttonChooseCoach: PropTypes.bool,
};

ProfileModalIcf.defaultProps = {
  buttonChooseCoach: false,
};

export default ProfileModalIcf;
