import React from 'react';
import PropTypes from 'prop-types';
import { Cell, Content } from '../styles';
import withWindowResize from '../../../../../shared/hocs/withWindowResize';
import { MEDIUM } from '../../../../../shared/constants/width-constants';

const GridPlansCellTitle = ({ children, cellPosition, width, gridColumns, ...props }) => {
  const gridColumn = width.lessThan(MEDIUM) ? '1 / span 2' : `1 / span ${gridColumns}`;
  const contentStyle = { ...props };
  switch (cellPosition) {
    case 'leftTopSecound':
      contentStyle.borderRadius = '1rem 0 0 0';
      break;
    case 'middleMiddle':
      contentStyle.borderRadius = '0';
      contentStyle.borderTop = 'none';
      break;
    default:
      break;
  }

  return (
    <Cell gridColumn={gridColumn}>
      <Content {...contentStyle}>{children}</Content>
    </Cell>
  );
};

GridPlansCellTitle.propTypes = {
  children: PropTypes.any,
  backgroundColor: PropTypes.string,
  gridColumn: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  width: PropTypes.any.isRequired,
  cellPosition: PropTypes.oneOf(['leftTopSecound', 'middleMiddle']),
  gridColumns: PropTypes.number,
};

GridPlansCellTitle.defaultProps = {
  children: {},
  backgroundColor: '#e8e8ec',
  gridColumn: '1 / span 3',
  fontSize: '1.7rem',
  fontWeight: '500',
  cellPosition: 'MiddleMiddle',
  gridColumns: 3,
};

export default withWindowResize(GridPlansCellTitle);
