import React from 'react';
import Header from '../../components/site-header';

const WithLayout = (Page) => props => {
  return (
    <>
      <Header {...props} />
      <Page {...props} />
    </>
  );
};

export default WithLayout;
