import React, { memo } from 'react';
import PropType from 'prop-types';
import { withTheme } from 'styled-components';
import Selected from './default';
import SelectedWbecs from './wbecs';

const SelectedContainer = props => {
  const {
    id,
    options,
    label,
    placeholder,
    className,
    value,
    onChange,
    isLoading,
    img,
    theme,
  } = props;

  return (
    <>
      {!theme ||
        (theme.theme !== 'wbecs' && (
          <Selected
            id={id}
            placeholder={placeholder}
            options={options}
            value={value}
            onChange={onChange}
            isLoading={isLoading}
            className={className}
            label={label}
            img={img}
          />
        ))}
      {theme.theme === 'wbecs' && (
        <SelectedWbecs
          id={id}
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={onChange}
          isLoading={isLoading}
          className={className}
          label={label}
          img={img}
        />
      )}
    </>
  );
};

SelectedContainer.defaultProps = {
  id: '',
  options: '',
  label: '',
  placeholder: 'Select ...',
  className: '',
  value: '',
  onChange: '',
  isLoading: '',
  img: '',
};

SelectedContainer.propTypes = {
  id: PropType.string,
  options: PropType.any,
  label: PropType.string,
  placeholder: PropType.string,
  className: PropType.string,
  value: PropType.any,
  onChange: PropType.any,
  isLoading: PropType.any,
  img: PropType.string,
};

export default memo(withTheme(SelectedContainer));
