import React from 'react';
import PropTypes from 'prop-types';
import withLayout from '../shared/hocs/layout';
import {getDirectory} from '../serviceWorker';
import { withTheme } from 'styled-components';
import { useParams } from 'react-router-dom';
import WithLayout from '../shared/hocs/layout';

const CustomPage = ({theme}) => {
    let params = useParams();
    const directory = getDirectory();
    let content = '';
    try {
        content = require(`html-loader!../content/${directory}/pages/${params.page}.html`);
    } catch (e) {}
    return (
        <div style={theme.mainContainer ?? null}>
            <div style={theme.contentContainer}>
                <div dangerouslySetInnerHTML={{ __html: content } } />
            </div>
        </div>
    )
};

CustomPage.propTypes = {
    page: PropTypes.string,
    theme: PropTypes.object,
};

CustomPage.defaultProps = {
    page: '',
    theme: {},
};

export default withTheme(WithLayout(CustomPage));
