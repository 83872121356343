import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { withTheme } from 'styled-components';

const HelpModal = props => {
  const { show, handleClose, theme } = props;

  const customStyles = {
    content: {
      zIndex: 2,
      border: 'none',
      background: 'none',
      top: '0',
      left: '0px',
      right: '0px',
      display: 'flex',
      justifyContent: 'center',
      bottom: 'auto',
      outline: 'none',
      padding: '30px',
      height: 'fit-content',
      alignSelf: 'center',
    },
  };

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'unset';
    ReactModal.setAppElement('body');
  }, [show]);

  return (
    <>
      <ReactModal
        isOpen={show}
        contentLabel="Minimal Modal Example"
        style={customStyles}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick
        className="pure-u-xl-3-5 pure-u-lg-4-5 pure-u-md-7-8 pure-u-sm-5-5 help-container"
      >
        <div className="help-modal-container pure-u-xl-5-5 pure-u-lg-5-5pure-u-md-5-5 pure-u-sm-5-5">
          <div className="help-modal">
            <div className="help-modal-header">
              <p className="title">{theme.help.title}</p>
            </div>

            <div className="content">
              <p>
                {theme.help.textContact}
                <a href={`mailto:${theme.help.contactEmail}`}>{theme.help.contactEmail}</a>
              </p>

              <p>
                {theme.help.textSupport}
                <a href={`mailto:${theme.help.supportEmail}`}>{theme.help.supportEmail}</a>
              </p>
            </div>
            <div className="help-modal-footer">
              <input type="button" className="close-help" onClick={handleClose} value="Close" />
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

HelpModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(HelpModal);
