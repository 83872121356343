/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Wrapper, Name, WrapperPrice, Price, Currency, Description, Link } from './styles';
import { setPlanObj } from '../../../actions/price-plans-actions';

const DetailPlan = ({ name, price, priceTotal, priceCurrency, description, button }) => {
  const dispatch = useDispatch();
  const isLoadingSubscription = useSelector(state => state.pricePlans.isLoadingSubscription);

  const handleOnClick = () => {
    if (isLoadingSubscription) return;
    dispatch(setPlanObj({
      id: process.env[button.planId],
      name,
      price,
      priceTotal,
      priceCurrency,
      description
    }));
  };
  
  return (
    <Wrapper>
      {name && <Name>{name}</Name>}
      <WrapperPrice>
        {price && <Price>{price}</Price>}
        {priceCurrency && <Currency>{priceCurrency}</Currency>}
      </WrapperPrice>
      {description && <Description>{description}</Description>}
      {button && (
        <Link onClick={handleOnClick} href="#form-subscription" disabled={isLoadingSubscription}>
          {button.description}
        </Link>
      )}
    </Wrapper>
  );
};

DetailPlan.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  priceTotal: PropTypes.string,
  priceCurrency: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.object,
};

DetailPlan.defaultProps = {
  name: '',
  price: null,
  priceTotal: '',
  priceCurrency: '',
  description: '',
  button: null,
};

export default DetailPlan;
