import styled from 'styled-components';
import media from 'styled-media-query';

export const Logo = styled.img`
  max-height: 4rem;

  ${media.greaterThan('1024px')`
    max-height: 8rem;
  `}
`;
