import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { isEmail } from '../../../../shared/helpers/validation-helper';
import {
  setErrorMessageSubscription,
  setIsLoadingSubscription,
  setFieldEmail,
  setFieldCardIsValid,
  setFieldEmailWasVerified,
  setFieldEmailIsValid,
  getUserByEmail,
  postUser,
  getPlansUser,
  alterPlan,
  setSuccessMessageSubscription,
  setFieldEmailIsNewUser,
} from '../../../actions/subscription-actions';
import LoaderBubble from '../../../../shared/components/loader-bubble';
import {
  Form,
  WrapperDescription,
  PlanName,
  Billed,
  Price,
  Label,
  InputEmail,
  WrapperInput,
  Danger,
  Button
} from './styles';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#a0a6cc',
      color: '#000000',
      fontFamily: 'inherit',
      fontSize: '15px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#000000' },
      '::placeholder': { color: '#5c5c5e' },
    },
    invalid: {
      iconColor: '#842029',
      color: '#842029',
    },
  },
};

const FormNewCard = () => {
  const dispatch = useDispatch();
  const { id: planId, ...planObj } = useSelector(state => state.pricePlans.planObj);
  const fieldEmail = useSelector(state => state.pricePlans.fieldEmail);
  const fieldCardIsValid = useSelector(state => state.pricePlans.fieldCardIsValid);
  const errorMessageSubscription = useSelector(state => state.pricePlans.errorMessageSubscription);
  const isLoadingSubscription = useSelector(state => state.pricePlans.isLoadingSubscription);
  const period = useSelector(state => state.pricePlans.period);

  const stripe = useStripe();
  const elements = useElements();

  const handleChangeEmail = ({ target: { value } }) => {
    dispatch(setErrorMessageSubscription(''));
    dispatch(setFieldEmail(value));
    dispatch(setFieldEmailWasVerified(false));
    dispatch(setFieldEmailIsValid(false));
  };
  const handleChangeCard = e => {
    dispatch(setErrorMessageSubscription(''));
    dispatch(setFieldCardIsValid(e.complete));
  }

  const handleSubmit = async e => {
    e.preventDefault();

    dispatch(setIsLoadingSubscription(true));
    dispatch(setErrorMessageSubscription(''));

    if (isEmail(fieldEmail)) {
      try {
        const data = { user: {}, coachPlans: {} };

        try { // Step 1/4: get user
          const dataUser = await getUserByEmail(fieldEmail);
          if (dataUser.id) data.user = dataUser;
          else data.user = await postUser(fieldEmail);
          dispatch(setFieldEmailIsNewUser(!data.user.auth0Id));
        } catch (e) { throw new Error('Server error'); }
        
        try { // Step 2/4: get user plans
          data.coachPlans = await getPlansUser(data.user.id);
        } catch (e) { throw new Error('Server error'); }
        
        try { // Step 3/4: get stripe token
          const element = elements.getElement(CardElement);
          const { token } = await stripe.createToken(element, { type: 'card' });
          const { coachPlans } = data;
          const { user: { id: userId } } = data;
          if (token && token.id && token.card) { // Step 4/4: set user plan
            await alterPlan({ cardToken: token.id, userId, coachPlans, newPlanId: planId })
              .then(() => {
                dispatch(setSuccessMessageSubscription('Thank you!'));
              });
          } else throw new Error('Stripe token error');
        } catch (e) { throw new Error(e.message); }
      } catch (e) {
        dispatch(setErrorMessageSubscription(e.message));
      }
    } else {
      dispatch(setFieldEmailWasVerified(true));
      dispatch(setFieldEmailIsValid(false));
    }
    dispatch(setIsLoadingSubscription(false));
  };

  const buttonDisable = !isEmail(fieldEmail) || !fieldCardIsValid || isLoadingSubscription;

  const priceTotal = process.env[planObj.priceTotal];

  return (
    <Form onSubmit={handleSubmit}>

      {isLoadingSubscription && <LoaderBubble />}

      <WrapperDescription>
        <PlanName>{planObj.name}</PlanName>
        <Billed>Billed {period}</Billed>        
        <Price>${priceTotal} {planObj.priceCurrency}</Price>
      </WrapperDescription>

      <WrapperInput>
        <Label>
          Email
          <InputEmail name="email" value={fieldEmail} onChange={handleChangeEmail} />
        </Label>        
      </WrapperInput>

      <WrapperInput>
        <CardElement
          onChange={handleChangeCard}
          options={CARD_OPTIONS}
        />
      </WrapperInput>

      {errorMessageSubscription && (
        <Danger>
          Ops! Something went wrong, please reload this page or contact our support team.
        </Danger>
      )}

      <Button disabled={buttonDisable}>Pay ${priceTotal}</Button>
    </Form>
  );
};

export default FormNewCard;
